import React, { useState, useEffect } from "react";
import { Modal, Input, notification, Select, Row, Col, Spin, Radio, Form, Checkbox, DatePicker } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { InputCurrency } from "@components/Input";
import { api_createCards, api_updateCard, api_getCards } from "./apiStudent";
import { api_getAllCourses } from "@api/globalApi";
import { numberWithCommas, numberWithoutCommas } from "@utils";
import debounce from "lodash/debounce";
import moment from "moment";
import PropTypes from "prop-types";
import "./index.scss";

const { Option } = Select;
function ModalComponent(props) {
    //props
    const {
        fetch,
        visible,
        isUpdate,
        setShowModal,
        dataModal,
        showModal,
        pagination,
        branchRedux,
        listBranch,
        student_id,
        filterData,
    } = props;

    //define form instance
    const [form] = NewForm.useForm();

    //state
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [isLimited, setIsLimited] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const [listCards, setListCards] = useState([]);
    const [isCreateBill, setIsCreateBill] = useState(true);

    useEffect(() => {
        _getListCards();
        fetchCourses();
        if (showModal && isUpdate) {
            setIsLimited(!!dataModal.number_lesson);
            form.setFieldsValue({
                type_card_id: listCards.find((item) => item.type_card == dataModal.type_card).id || "",
                is_limited: !!dataModal.number_lesson,
                number_lesson: dataModal.number_lesson || null,
                value: dataModal.value ? numberWithCommas(dataModal.value) : null,
                course_ids: dataModal.course_ids.length !== 0 ? dataModal.course_ids : [],
                active: !!dataModal.active,
                discount: dataModal.discount || null,
                branch_id: dataModal.branch_id || null,
                begin_date: dataModal.begin_date ? moment(dataModal.begin_date, "YYYY-MM-DD") : null,
                end_date: dataModal.end_date ? moment(dataModal.end_date, "YYYY-MM-DD") : null,
            });
        } else {
            setIsLimited(true);
            form.setFieldsValue({
                type_card_id: "",
                is_limited: true,
                value: "",
                number_lesson: "",
                course_ids: [],
                active: true,
                discount: "",
                branch_id: branchRedux,
                create_bill: true,
                begin_date: null,
                end_date: null,
            });
        }
    }, [showModal, isUpdate, dataModal]);

    const fetchCourses = async (value = "") => {
        try {
            setFetching(true);
            const param = {
                _page: 1,
                _filter: `slug:${value};`,
            };
            const res = await api_getAllCourses(param);
            if (res.status) {
                setFetching(false);
                setCoursesList(res.data);
            } else {
                setFetching(false);
                setCoursesList([]);
            }
        } catch (err) {
            setFetching(false);
            setCoursesList([]);
        }
    };

    const _getListCards = async () => {
        try {
            const res = await api_getCards({ paging: 0 });
            if (res.status) {
                setListCards(res.data);
            } else {
                setListCards([]);
            }
        } catch (err) {
            setListCards([]);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setIsCreateBill(true);
        setShowModal(false);
    };

    const onSubmitRow = () => {
        setConfirmLoading(true);
        form.validateFields()
            .then(async (values) => {
                const dataSubmit = { ...values };
                dataSubmit.type_card = listCards.find((item) => item.id == dataSubmit.type_card_id).type_card || "";
                dataSubmit.begin_date = dataSubmit?.begin_date?.format("YYYY-MM-DD") || null;
                dataSubmit.end_date = dataSubmit?.end_date?.format("YYYY-MM-DD") || null;
                dataSubmit.discount = parseInt(dataSubmit.discount) || null;
                dataSubmit.number_lesson = is_limited
                    ? dataSubmit.number_lesson
                        ? parseInt(dataSubmit.number_lesson)
                        : null
                    : null;
                dataSubmit.value = parseInt(numberWithoutCommas(dataSubmit.value));
                dataSubmit.student_id = parseInt(student_id);
                delete dataSubmit.is_limited;
                if (isUpdate) {
                    const res = await api_updateCard(dataModal.id, dataSubmit);
                    if (res.status) {
                        fetch(pagination.current, _, filterData);
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Cập nhật thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                } else {
                    const res = await api_createCards(dataSubmit);
                    if (res.status) {
                        fetch(1, _, filterData);
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Tạo mới thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                }
            })
            .catch((err) => {
                setConfirmLoading(false);
                notification.error({
                    message: "Cảnh báo",
                    description: "Không thể gửi yêu cầu, vui lòng thử lại",
                });
            });
    };

    const handleChangeValue = (e) => {
        form.setFieldsValue({ value: e.target.value ? numberWithCommas(e.target.value) : "" });
    };

    const handleChangeCard = (value) => {
        const data = listCards.find((item) => item.id == value);
        setIsLimited(!!data.number_lesson);
        form.setFieldsValue({
            type_card_id: value,
            is_limited: !!data.number_lesson,
            number_lesson: data.number_lesson || null,
            value: data.value ? data.value : null,
            course_ids: data.course_ids,
            active: data.active,
            discount: data.discount,
        });
    };

    return (
        <div>
            <Modal
                centered
                title={isUpdate ? "Cập nhật thẻ" : "Thêm thẻ"}
                cancelText="Hủy"
                okText={isUpdate ? "Cập nhật" : "Lưu"}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                onOk={onSubmitRow}
            >
                <NewForm className="new-form modal-form" form={form}>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Tên thẻ"
                                name="type_card_id"
                                rules={[{ required: true, message: "Vui lòng chọn loại thẻ thành viên!" }]}
                            >
                                <Select onChange={handleChangeCard}>
                                    {listCards.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.type_card}
                                        </Option>
                                    ))}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem label="Số buổi" name="is_limited">
                                <Select onChange={(value) => setIsLimited(value)}>
                                    <Option value={false}>Không giới hạn</Option>
                                    <Option value={true}>Có giới hạn</Option>
                                </Select>
                            </FieldFloatItem>
                        </Col>
                        {isLimited && (
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Nhập số buổi"
                                    name="number_lesson"
                                    rules={[{ required: true, message: "Vui lòng nhập số buổi!" }]}
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Giá trị" name="value">
                                <InputCurrency onChange={handleChangeValue} />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Các khóa học được áp dụng"
                                name="course_ids"
                                rules={[{ required: true, message: "Vui lòng chọn các khóa học!" }]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    // onSearch={debounce(fetchCourses, 250)}
                                >
                                    <Option value="all">Tất cả các khóa</Option>
                                    {coursesList.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Giảm giá (%)" name="discount">
                                <Input />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Ngày đăng ký"
                                name="begin_date"
                                rules={[{ required: true, message: "Vui lòng chọn ngày đăng ký thẻ!" }]}
                            >
                                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder={null} />
                            </FieldFloatItem>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Ngày hết hạn"
                                name="end_date"
                                rules={[{ required: true, message: "Vui lòng chọn ngày hết hạn thẻ!" }]}
                            >
                                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder={null} />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <Form.Item label="Trạng thái" name="active">
                                <Radio.Group>
                                    <Radio value={true}>Đang mở</Radio>
                                    <Radio value={false}>Khóa</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    {isUpdate || (
                        <>
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col span={24}>
                                    <Form.Item label="" name="create_bill" valuePropName="checked">
                                        <Checkbox onChange={(e) => setIsCreateBill(e.target.checked)}>
                                            Xuất phiếu thu
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {isCreateBill && (
                                <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                    <Col span={24}>
                                        <FieldFloatItem
                                            label="Chi nhánh"
                                            name="branch_id"
                                            rules={[{ required: true, message: "Vui lòng chọn chi nhánh!" }]}
                                        >
                                            <Select>
                                                {listBranch.map((item) => (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </NewForm>
            </Modal>
        </div>
    );
}

ModalComponent.propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    dataModal: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
};

export default ModalComponent;
