import api from "@api";
import { useHistory } from "react-router-dom";

export const api_getListStudentMoney = (param) => {
    const url = `statistic/remind_students_to_pay`;
    return api.get(url, {
        params: {
            branch_id: param?.branch_id,
        },
    });
};

export const getListStudent = async (params) => {
    const url = "/student";
    return api.get(url, {
        params,
    });
};

export const createStudent = async (param) => {
    const url = "/student";
    return api.post(url, param);
};

export const updateStudent = async (student_id, param) => {
    const url = "/student/" + student_id;
    return api.put(url, param);
};

export const deleteStudent = async (student_id) => {
    const url = "/student/" + student_id;
    return api.delete(url);
};

export const getStudent = async (student_id) => {
    const url = "/student/" + student_id;
    return api.get(url);
};

//student-course
export const getListCourse = async (params) => {
    // let history = useHistory();
    const url = "/student/course";
    return api
        .get(url, {
            params,
        })
        .catch((err) => (window.location.href = "/student"));
};

export const createStudentCourse = async (param) => {
    const url = "/student/course";
    return api.post(url, param);
};

export const getCourseOfType = async (params) => {
    const url = "/course";
    return api.get(url, {
        params,
    });
};

export const getEndCourse = async (data) => {
    const url = "/course/get_end_date";
    return api.get(url, {
        params: data,
    });
};

export const updateStudentCourse = async (data) => {
    const url = "/student/course";
    return api.put(url, data);
};

export const cancelStudentCourse = async (course_id, params) => {
    const url = "/student_course_cancel/" + course_id;
    return api.put(url, {
        params,
    });
};

export const reverseStudentCourse = async (course_id, params) => {
    const url = "/student_course_reserve/" + course_id;
    return api.put(url, {
        params,
    });
};

export const getStudentCourse = async (course_id, params) => {
    const url = "/student/course/" + course_id;
    return api.get(url, {
        params,
    });
};

//student-course
export const getListLesson = async (params) => {
    const url = "/lesson";
    return api.get(url, {
        params,
    });
};

export const getStudentLesson = async (lesson_id) => {
    const url = "/lesson/" + lesson_id;
    return api.get(url);
};

export const updateStudentLesson = async (lesson_id, data) => {
    const url = "/lesson/" + lesson_id;
    return api.put(url, data);
};

export const api_checkin = (student_id) => {
    const url = `course/checkin_student/${student_id}`;
    return api.post(url);
};

export const api_getCards = (params) => {
    const url = `/card`;
    return api.get(url, params);
};

export const api_createCards = (dataSubmit) => {
    const url = `/membership_card`;
    return api.post(url, dataSubmit);
};

export const api_updateCard = (id, dataSubmit) => {
    const url = `/membership_card/${id}`;
    return api.put(url, dataSubmit);
};

export const api_removeCard = (id) => {
    const url = `/membership_card/${id}`;
    return api.delete(url);
};

export const api_getAllStudentMemberShipCards = (params) => {
    const url = `/membership_card`;
    return api.get(url, params);
};

export const api_getStudentMemberShipCard = (membership_card_id, params) => {
    const url = `/membership_card/${membership_card_id}`;
    return api.get(url, params);
};

export const api_getClassTypeByBrach = (branch_id) => {
    const url = `/branch_class_type/${branch_id}`;
    return api.get(url);
};

export const api_getEndDateCourse = (params) => {
    const url = `/card/get_end_date`;
    return api.get(url, params);
};
