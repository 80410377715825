import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

import { useHistory, useParams } from "react-router-dom";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsLesson";
import { getHeightOfMainContent } from "@utils";
import "./index.scss";
import { getListLesson, api_checkin } from "./apiStudent";

let listStatus = [
    { id: "1", name: "Sẵn sàng" },
    { id: "2", name: "Hoàn thành" },
    { id: "3", name: "Xin nghỉ" },
    { id: "4", name: "Bảo lưu" },
    { id: "5", name: "Hủy" },
];

function StudentCourseComponent() {
    let { student_id, course_id, student_course_id } = useParams();
    let history = useHistory();
    // const acl = useSelector((state) => state.acl.acl);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const [infoLearnStudent, setInfoLearnStudent] = useState(null);
    // const [nameCourse, setNameCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });
    // const [isBtnCheckInShowed, setIsBtnCheckInShowed] = useState(false);

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const fetch = async (page = 0, sort = "id:asc") => {
        setLoading(true);
        const res = await getListLesson({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _relations: "student_course",
            _filter: `student_course_id:${student_course_id}`,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            // let currentDate = new Date();
            // let newDateString = `${currentDate.getFullYear()}-${
            //     currentDate.getMonth() + 1 > 10 ? currentDate.getMonth() + 1 : "0" + (currentDate.getMonth() + 1)
            // }-${currentDate.getDate() > 10 ? currentDate.getDate() : "0" + currentDate.getDate()}`;

            // setIsBtnCheckInShowed(res.data?.some((item) => item.learn_date === newDateString && item.status === 4));
            setData(res.data);
            setPagination(newpagination);
            // setNameStudent(res.student_name);
            // setNameCourse(res.course_name);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
        setInfoLearnStudent({
            name: res.student_name,
            course: res.course_name,
            learnLesson: res.lesson_learned,
            totalLesson: res.lesson_total,
        });
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listStatus,
        });
        setColumns(columnConfig);
    }, [pagination]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        fetch();
        // setColumns(newColumns);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        history.push({
                            pathname: "/update-student-lesson/" + student_id + "/" + course_id + "/" + record.id,
                        });
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
            ],
        });
    }, []);

    const handleCheckIn = async () => {
        let res = await api_checkin(student_course_id);
        if (res.status) {
            notification.success({
                message: "Thông báo",
                description: res.message || "Điểm danh thành công",
            });
        } else {
            notification.error({
                message: "Cảnh báo",
                description: res.message || "Điểm danh thất bại",
            });
        }
    };

    return (
        <>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="name-breadcrumb">Học viên</Breadcrumb.Item>
                <Breadcrumb.Item className="name-breadcrumb">
                    <Link to={"/student-course/" + student_id} className="is-link">
                        Khóa học
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="name-breadcrumb">
                    <Link to={"/student-lesson/" + student_id + "/" + course_id + "/" + student_course_id}>
                        Buổi học
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="pn-container-student">
                <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                        <span>Học viên:</span>
                        <span className="name-student">{infoLearnStudent && infoLearnStudent.name}</span>
                    </Col>
                    <Col>
                        <span>Khóa học:</span>
                        <span className="name-student">{infoLearnStudent && infoLearnStudent.course}</span>
                    </Col>
                    <Col>
                        <span>Số buổi đã học:</span>
                        <span className="name-student">
                            {infoLearnStudent && infoLearnStudent.learnLesson}/
                            {infoLearnStudent && infoLearnStudent.totalLesson}
                        </span>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={handleCheckIn}>
                            Điểm danh
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 0 }}>
                    <Table
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                            // x: 1800,
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </div>
        </>
    );
}

export default StudentCourseComponent;
