import axios from "axios";
import Config from "@config";
import { storeToken, removeToken } from "@utils/auth";
import { fromJson, toJson } from "@utils/json";
import { notification } from "antd";

let token = localStorage.getItem("token");

let normalAxios = axios.create({
    baseURL: Config.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

normalAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

// Interceptor request
normalAxios.interceptors.request.use(
    function (config) {
        // if (!Config.BROWSER) {
        //     if (config.url.split("?")[1]) {
        //         config.url = config.url + "&mobile=true";
        //     } else {
        //         config.url = config.url + "?mobile=true";
        //     }
        // }
        // Do something before request is sent
        if (config.method !== "get") {
            if (!navigator.onLine) {
                let dataBackup = localStorage.getItem("dataBackup") ? fromJson(localStorage.getItem("dataBackup")) : [];
                dataBackup.push({
                    url: config.url.split("/api/v1")[1],
                    method: config.method,
                    data: config.data,
                });
                localStorage.setItem("dataBackup", toJson(dataBackup));
                let action = config.method === "post" ? "Create" : config.method === "put" ? "Update" : "Delete";
                // Toast.success(action + " successfull!", 1);
                if (action === "Create") {
                    window.history.back();
                }
            }
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Interceptor response
normalAxios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response.data;
    },
    function (error) {
        if (error.response && error.response.status === 400) {
            notification.error({
                message: "Cảnh báo",
                description: error?.response?.data?.message || "Lỗi hệ thống",
            });
        } else if (error.response && error.response.status === 401) {
            removeToken();
            window.location.href = "/login";
        } else if (error.response && error.response.status === 403) {
            if (error.response.data.message === "Role Invalid") {
                // window.location.href = "/";
                notification.error({
                    message: "Cảnh báo",
                    description: "Tài khoản không có quyền" || error.response.data.message,
                });
            } else if (error.response.data.message === "Token Expired") {
                if (localStorage.getItem("rem") === "true") {
                    axios.post(Config.BASE_URL + "/user/refresh").then(function (res) {
                        storeToken(res.data.token).then(function () {
                            error.config.headers.Authorization = "Bearer " + res.data.token;
                            return axios(error.config);
                        });
                    });
                } else {
                    removeToken();
                    window.location.href = "/login";
                }
            }
        } else if (error.response && error.response.status === 503) {
            // System maintenance
            window.location.href = "/maintenance";
        }

        return Promise.reject(error);
    }
);

export default normalAxios;
