import React from "react";
import { numberWithCommas } from "@utils";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";

import { Popover } from "antd";
import ConfigColumnsForTable from "@components/ConfigColumnsForTable";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, listBranch, fixColumns, setConfigColumns, history, tableName } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/invoice");
        }
    };
    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            key: "stt",
            width: 70,
            fixed: "left",
            align: "center",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Mã phiếu</span>,
            key: "code",
            width: 170,
            dataIndex: "code",
            align: "center",
        },
        {
            title: <span className="dragHandler">Loại</span>,
            key: "type",
            width: 100,
            dataIndex: "type",
            render: (text, record) => <span>{record.type == 1 ? "Thu" : "Chi"}</span>,
            align: "center",
        },
        {
            title: <span className="dragHandler">Họ tên</span>,
            key: "name",
            width: 170,
            dataIndex: "name",
            align: "center",
        },
        {
            title: <span className="dragHandler">Chi nhánh</span>,
            key: "branch_id",
            dataIndex: "branch_id",
            width: 170,
            render: (text, record) => {
                let branch = listBranch && listBranch.filter((item) => item.id == text);
                return <div>{branch[0]?.name}</div>;
            },
            align: "center",
        },
        {
            title: <span className="dragHandler">Số điện thoại</span>,
            key: "phone",
            width: 150,
            dataIndex: "phone",
            align: "center",
        },
        {
            title: <span className="dragHandler">Nội dung</span>,
            key: "content",
            dataIndex: "content",
            width: 250,
            align: "center",
        },
        {
            title: <span className="dragHandler">Trạng thái</span>,
            key: "status",
            width: 150,
            dataIndex: "status",
            render: (text, record) => (
                <span>
                    {record.status
                        ? record.status == 1
                            ? "Chưa thanh toán"
                            : record.status == 2
                            ? "Đã thanh toán"
                            : record.status == 3
                            ? "Đã huỷ"
                            : ""
                        : ""}
                </span>
            ),
            filters: [
                { text: "Chưa thanh toán", value: 1 },
                { text: "Đã thanh toán", value: 2 },
                { text: "Đã huỷ", value: 3 },
            ],
            filterMultiple: false,
            align: "center",
        },
        {
            title: <span className="dragHandler">Số tiền</span>,
            key: "amount",
            width: 170,
            dataIndex: "amount",
            align: "center",
            render: (text, record) => <span>{`${numberWithCommas(record.amount)} VNĐ`}</span>,
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
