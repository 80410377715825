import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Modal,
    Button,
    Form,
    Input,
    Select,
    TimePicker,
    Row,
    Col,
    message,
    Tabs,
    DatePicker,
    notification,
} from "antd";
import moment from "moment";

import CenterArea from "@components/CenterArea";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { validateObject, numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { api_createCourse, api_getOneCourse, api_updateCourse, api_getStudentsOfCourse } from "./apiCourse";
import { api_getAllTeacher, api_getAllBranchOptions, api_getAllTypeOfClass } from "@api/globalApi";
import TabStudent from "./TabStudent";
import { getRole } from "@utils/auth";

const { TabPane } = Tabs;
const { Option } = Select;

const Detail = (props) => {
    const { history } = props;
    const [form] = NewForm.useForm();
    const user = useSelector((state) => state.branch);
    const [listTeacher, setListTeacher] = useState([]);
    const [listBranch, setBranch] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [isContinuityCourse, setIsContinuityCourse] = useState(false);
    const acl = useSelector((state) => state.acl.acl);
    const { id } = props.match.params;

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            values.begin_date = values.begin_date ? values.begin_date : null;
            values.end_date = !isContinuityCourse ? values.end_date : null;
            values.continuity = +values.continuity;
            values.duration = +values.duration || null;
            values.tuition = numberWithoutCommas(values.tuition);

            let dataSubmit = createSubmitData(values);
            let check = false;
            ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"].forEach((key) => {
                if (dataSubmit[[key]].start_time && dataSubmit[[key]].end_time) {
                    check = true;
                    return;
                }
            });
            if (!check) {
                notification.error({
                    message: "Cảnh báo",
                    description: "Vui lòng chọn thời gian bắt đầu và kết thúc cho các thứ!",
                });
                return;
            }
            if (id) {
                const res = await api_updateCourse({
                    id,
                    data: { ...dataSubmit },
                });
                if (res.status) {
                    message.success("Cập nhật thành công");
                    history.push("/course");
                } else {
                    message.error("Cập nhật thất bại, vui lòng thử lại sau");
                }
            } else {
                const res = await api_createCourse({
                    ...dataSubmit,
                });
                if (res.status) {
                    message.success("Tạo thành công");
                    history.push("/course");
                } else {
                    message.error("Tạo thất bại, vui lòng thử lại sau");
                }
            }
        });
    };
    useEffect(() => {
        _getAllTeacher();
        _getAllTypeOfClass();
        if (id) {
            _getCourse();
        } else {
            form.setFieldsValue({
                status: 1,
                gender: 1,
                branch_id: user.branch_id,
                continuity: "0",
                begin_date: moment(new Date(), "YYYY-MM-DD"),
                end_date: moment(new Date(), "YYYY-MM-DD"),
            });
        }
        const _getAllBranch = async () => {
            try {
                let params = { params: { _orderBy: "id:asc" } };
                const res = await api_getAllBranchOptions(params);
                if (res.status) {
                    if (res.data) {
                        setBranch(toArray(res.data));
                    }
                } else {
                    console.log("hello: ", res);
                }
            } catch (err) {
                setBranch([]);
            }
        };

        _getAllBranch();
        api_getStudentsOfCourse({ id });
        return () => {
            if (!history.location.pathname.includes("/course")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const _getCourse = async () => {
        const res = await api_getOneCourse(id);
        if (res.status) {
            const {
                name,
                status,
                note,
                code,
                teacher_id,
                tuition,
                type_id,
                duration,
                branch_id,
                continuity,
            } = res.data;
            setIsContinuityCourse(continuity);
            form.setFieldsValue({
                name,
                status,
                note,
                code,
                teacher_id,
                tuition: tuition ? numberWithCommas(tuition) : 0,
                begin_date: res.data.begin_date ? moment(res.data.begin_date, "YYYY-MM-DD") : null,
                end_date: res.data.end_date ? moment(res.data.end_date, "YYYY-MM-DD") : null,
                duration,
                continuity: continuity ? "1" : "0",
                branch_id,
                type_id,
            });
            const arr = ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"];
            arr.forEach((item) => {
                form.setFieldsValue({
                    [item]: {
                        start_time: res.data[item].start_time ? moment(res.data[item].start_time, "HH:mm") : null,
                        end_time: res.data[item].end_time ? moment(res.data[item].end_time, "HH:mm") : null,
                    },
                });
            });
        }
    };

    const _getAllTeacher = async () => {
        try {
            const res = await api_getAllTeacher({ paging: 0 });
            if (res.status) {
                setListTeacher(res.data);
            } else {
                setListTeacher([]);
            }
        } catch (err) {
            setListTeacher([]);
        }
    };

    const _getAllTypeOfClass = async () => {
        try {
            const res = await api_getAllTypeOfClass({ paging: 0 });
            if (res.status) {
                setListTypeOfClass(res.data);
            } else {
                setListTypeOfClass([]);
            }
        } catch (err) {
            setListTypeOfClass([]);
        }
    };

    const createSubmitData = (values) => {
        let data = validateObject(values);
        data.begin_date = data?.begin_date?.format("YYYY-MM-DD") || null;
        data.end_date = data?.end_date?.format("YYYY-MM-DD") || null;

        ["day_1", "day_2", "day_3", "day_4", "day_5", "day_6", "day_7"].forEach((key) => {
            data[[key]] = {
                start_time: data?.[key]?.start_time?.format("HH:mm:ss") || null,
                end_time: data?.[key]?.end_time?.format("HH:mm:ss") || null,
                teacher_id: data?.[key]?.teacher_id || null,
            };
        });

        data.tuition = numberWithoutCommas(data.tuition);
        data.status_id = data.status_id || 1;

        return data;
    };

    const handleChangeTypeCourse = (value) => {
        if (value === "1") {
            form.setFieldsValue({
                duration: "",
            });
            setIsContinuityCourse(true);
        } else {
            setIsContinuityCourse(false);
        }
    };

    const handleBack = () => {
        props.history.push({
            pathname: `/course`,
        });
    };

    const showConfirm = () => {
        Modal.confirm({
            title: "Bạn có chắc chắn muốn cập nhật?",
            onOk() {
                onFinish();
            },
        });
    };

    return (
        <Tabs defaultActiveKey={1}>
            <TabPane tab="Thông tin khoá học" key={1}>
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm id="course-editor-form" className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    label="Chi nhánh"
                                    name="branch_id"
                                    rules={[{ required: true, message: "Vui lòng chọn khóa học!" }]}
                                >
                                    <Select>
                                        {listBranch &&
                                            listBranch.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem label="Mã khoá học" name="code">
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Nhập tên khoá học!" }]}
                                    label="Tên khoá học"
                                    name="name"
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={12}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Chọn loại lớp!" }]}
                                    label="Loại lớp"
                                    name="type_id"
                                >
                                    <Select>
                                        {listTypeOfClass.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                            <Col xs={24} lg={24}>
                                <FieldFloatItem
                                    rules={[{ required: true, message: "Chọn giáo viên!" }]}
                                    label="Giáo viên"
                                    name="teacher_id"
                                >
                                    <Select>
                                        {listTeacher.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={index}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                        {/* <Select.Option value="1">Đang dạy</Select.Option>
                                <Select.Option value="2">Đã nghỉ</Select.Option> */}
                                    </Select>
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} lg={12}>
                                <FieldFloatItem label="Loại khoá học" name="continuity">
                                    <Select onChange={handleChangeTypeCourse}>
                                        <Option value={"0"}>Khoá cố định</Option>
                                        <Option value={"1"}>Khoá luôn luôn mở</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} lg={12}>
                                <FieldFloatItem label="Trạng thái" name="status">
                                    <Select>
                                        <Option value={1}>Đang mở</Option>
                                        <Option value={2}>Đã đóng</Option>
                                    </Select>
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24}>
                                <Row>
                                    <Col xs={24}>
                                        <Row>
                                            <Col xs={24} md={3} lg={2}>
                                                Lịch học:
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 10 }} gutter={{ xs: 8, sm: 8, md: 16 }}>
                                            <Col xs={24} md={6}>
                                                <FieldFloatItem label="Thời gian bắt đầu" name="begin_date">
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        format="DD/MM/YYYY"
                                                        placeholder={null}
                                                    />
                                                </FieldFloatItem>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                {isContinuityCourse || (
                                                    <FieldFloatItem label="Thời gian kết thúc" name="end_date">
                                                        <DatePicker
                                                            style={{ width: "100%" }}
                                                            format="DD/MM/YYYY"
                                                            placeholder={null}
                                                        />
                                                    </FieldFloatItem>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24}>
                                        <Row
                                            gutter={{
                                                xs: 8,
                                                sm: 8,
                                                md: 16,
                                            }}
                                        >
                                            <Col xs={24} sm={24} md={2}></Col>
                                            <Col xs={24} sm={24} md={9}>
                                                <Row
                                                    gutter={{
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 16,
                                                    }}
                                                >
                                                    <Col span={12}>
                                                        <div>Bắt đầu</div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div>Kết thúc</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} lg={24}>
                                        {[
                                            ["1", "T2"],
                                            ["2", "T3"],
                                            ["3", "T4"],
                                            ["4", "T5"],
                                            ["5", "T6"],
                                            ["6", "T7"],
                                            ["7", "CN"],
                                        ].map((item, index) => {
                                            return (
                                                <Row
                                                    gutter={{
                                                        xs: 8,
                                                        sm: 8,
                                                        md: 16,
                                                    }}
                                                    key={index}
                                                >
                                                    <Col xs={24} sm={24} md={2}>
                                                        <label className="item-label">{item[1]}</label>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={9}>
                                                        <Row
                                                            gutter={{
                                                                xs: 8,
                                                                sm: 8,
                                                                md: 16,
                                                            }}
                                                        >
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    // label="Bắt đầu"
                                                                    name={[`day_${item[0]}`, "start_time"]}
                                                                >
                                                                    <TimePicker
                                                                        placeholder="Chọn thời gian"
                                                                        format="HH:mm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    // label="Kết thúc"
                                                                    name={[`day_${item[0]}`, "end_time"]}
                                                                >
                                                                    <TimePicker
                                                                        placeholder="Chọn thời gian"
                                                                        format="HH:mm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {/* <Col xs={24} sm={24} md={4}>
                                                <FieldFloatItem
                                                    label={t("quick_select")}
                                                    name={[`day_${item[0]}`, "autoShift"]}
                                                >
                                                    <Select
                                                        placeholder={null}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(value) =>
                                                            handleChangeShiftOnRecord(`day_${item[0]}`, value)
                                                        }
                                                    >
                                                        <Option value="bochon">Bỏ chọn</Option>
                                                        <Option value="08:30:00-10:00:00">8h30 - 10h00</Option>
                                                        <Option value="08:30:00-11:30:00">8h30 - 11h30</Option>
                                                        <Option value="09:00:00-11:15:00">9h00 - 11h15</Option>
                                                        <Option value="09:00:00-12:00:00">9h00 - 12h00</Option>
                                                        <Option value="10:30:00-12:00:00">10h30 - 12h00</Option>
                                                        <Option value="14:00:00-15:30:00">14h00 - 15h30</Option>
                                                        <Option value="14:00:00-16:15:00">14h00 - 16h15</Option>
                                                        <Option value="14:00:00-17:00:00">14h00 - 17h00</Option>
                                                        <Option value="16:00:00-17:30:00">16h00 - 17h30</Option>
                                                        <Option value="18:00:00-19:30:00">18h00 - 19h30</Option>
                                                        <Option value="18:00:00-21:00:00">18h00 - 21h00</Option>
                                                        <Option value="19:35:00-21:05:00">19h35 - 21h05</Option>
                                                    </Select>
                                                </FieldFloatItem>
                                            </Col> */}
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ marginTop: 15 }} xs={24}>
                                <FieldFloatItem label="Ghi chú" name="note">
                                    <Input.TextArea rows={3} />
                                </FieldFloatItem>
                            </Col>

                            <Col xs={24} sm={24} md={20}>
                                <FieldFloatItem>
                                    <Row style={{ marginBottom: 20, justifyContent: "space-between" }}>
                                        <Col>
                                            <Button type="default" onClick={handleBack} style={{ marginRight: 20 }}>
                                                Quay lại
                                            </Button>
                                            {acl.hasAllowed(getRole(), "course", "update") && (
                                                <Button
                                                    htmlType={id ? "button" : "submit"}
                                                    onClick={id ? showConfirm : null}
                                                    type="primary"
                                                >
                                                    {id ? "Cập nhật" : "Tạo mới"}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </FieldFloatItem>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {id && (
                <TabPane tab="Học viên của khoá" key={2}>
                    <TabStudent id={id} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default Detail;
