import api from "@api";

export const api_getListTypeOfClass = (params) => {
    const url = `/class_type`;
    return api.get(url, params);
};

export const api_createTypeOfClass = (dataSubmit) => {
    const url = `/class_type`;
    return api.post(url, dataSubmit);
};

export const api_updateTypeOfClass = (id, dataSubmit) => {
    const url = `/class_type/${id}`;
    return api.put(url, dataSubmit);
};

export const api_removeTypeOfClass = (id) => {
    const url = `/class_type_cancel/${id}`;
    return api.put(url);
};
