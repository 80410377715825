export default {
    student: "student-table",
    teacher: "teacher-table",
    invoice: "invoice-table",
    course: "course-table",
    branch: "branch-table",
    account: "account-table",
    role: "role-table",
    typeofclass: "typeofclass-table",
    card: "card-table",
};
