import api from "@api";

export const api_getAllPromotions = (params) => {
    const url = `/promotion`;
    return api.get(url, params);
};

export const api_createPromotion = (dataSubmit) => {
    const url = `/promotion`;
    return api.post(url, dataSubmit);
};

export const api_updatePromotion = (id, dataSubmit) => {
    const url = `/promotion/${id}`;
    return api.put(url, dataSubmit);
};

export const api_removePromotion = (id) => {
    const url = `/promotion/${id}`;
    return api.delete(url);
};
