import api from "@api";

export const api_getAllCards = (params) => {
    const url = `/card`;
    return api.get(url, params);
};

export const api_createCards = (dataSubmit) => {
    const url = `/card`;
    return api.post(url, dataSubmit);
};

export const api_updateCard = (id, dataSubmit) => {
    const url = `/card/${id}`;
    return api.put(url, dataSubmit);
};

export const api_removeCard = (id) => {
    const url = `/card/${id}`;
    return api.delete(url);
};

export const api_getBranchTypeOfClass = (params) => {
    const url = "/class_type";
    return api.get(url, {
        params,
    });
};
