import React from "react";
import { Popover } from "antd";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";
import ConfigColumnsForTable from "@components/ConfigColumnsForTable";
import { numberWithCommas } from "@utils";
import moment from "moment";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, fixColumns, setConfigColumns, history, tableName } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/card");
        }
    };

    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            fixed: "left",
            width: 80,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Chương trình khuyến mại</span>,
            dataIndex: "name",
            width: 250,
            align: "center",
            key: "name",
            render: (text, record, index) => {
                return <span>{record.name}</span>;
            },
        },
        {
            title: <span className="dragHandler">Mô tả</span>,
            dataIndex: "description",
            width: 150,
            align: "center",
            key: "description",
            render: (text, record, index) => {
                return <span>{record.description || "Cập nhật sau"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Giá trị</span>,
            dataIndex: "value",
            width: 150,
            align: "center",
            key: "value",
            render: (text, record, index) => {
                return (
                    <span>
                        {(record.value > 100 ? numberWithCommas(record.value) : record.value) || "Cập nhật sau"}
                    </span>
                );
            },
        },
        {
            title: <span className="dragHandler">Đơn vị tính</span>,
            dataIndex: "check_value",
            width: 80,
            align: "center",
            key: "check_value",
            render: (text, record, index) => {
                return <span>{record.check_value ? "%" : "VNĐ"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Số lượt sử dụng tối đa</span>,
            dataIndex: "count_use",
            width: 150,
            align: "center",
            key: "count_use",
            render: (text, record, index) => {
                return <span>{record.count_use || "Cập nhật sau"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Số lượt sử dụng hiện tại</span>,
            dataIndex: "count_user_used",
            width: 150,
            align: "center",
            key: "count_user_used",
            render: (text, record, index) => {
                return <span>{record.count_user_used || "Cập nhật sau"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Ngày áp dụng</span>,
            dataIndex: "begin_date",
            width: 120,
            align: "center",
            key: "begin_date",
            render: (text, record, index) => {
                return (
                    <React.Fragment>
                        {record.begin_date ? moment(record.begin_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
        {
            title: <span className="dragHandler">Ngày kết thúc</span>,
            dataIndex: "end_date",
            width: 120,
            align: "center",
            key: "end_date",
            render: (text, record, index) => {
                return (
                    <React.Fragment>
                        {record.end_date ? moment(record.end_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
        {
            title: <span className="dragHandler">Trạng thái</span>,
            dataIndex: "active",
            width: 120,
            align: "center",
            key: "active",
            filters: [
                { text: "Đang mở", value: 1 },
                { text: "Khóa", value: 0 },
            ],
            filterMultiple: false,
            render: (text, record, index) => {
                return <span>{record.active ? "Đang sử dụng" : "Hết hạn"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Ngày tạo</span>,
            dataIndex: "created_at",
            width: 120,
            align: "center",
            key: "created_at",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
