import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, DatePicker, Select, Tabs, Skeleton, Card } from "antd";
import moment from "moment";
import { useMedia } from "react-media";

import Student from "./Student/index";
import Money from "./Money";
import "./index.scss";
import LazyLoad from "../../components/LazyLoad";
import { api_getListStudentMoney } from "@api/globalApi";
import { api_activityToday } from "./apiDashboard";

const { Option } = Select;
let { TabPane } = Tabs;
const { Meta } = Card;
const monthArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Dashboard = (props) => {
    const { history } = props;
    const d = new Date();
    const [selectedMonth, setSelectedMonth] = useState(undefined);
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [openSelectYear, setOpenSelectYear] = useState(false);
    const [time, setTime] = useState(moment(d.getFullYear(), "YYYY"));
    const [loading, setLoading] = useState(true);
    const isMobileScreen = useMedia({ query: "(min-width: 576px)" });
    const [recapInfor, setRecapInfor] = useState({
        countRemindStudentsToPay: 0,
        countCoursesOngoing: 0,
        teacherBirthday: 0,
        studentBirthday: 0,
    });

    const handleSourceMonth = (month) => {
        setSelectedMonth(month);
    };

    const _fetchRecapInfor = async () => {
        const res = await api_getListStudentMoney({ branch_id: branchRedux || "" });
        if (res.status) {
            const resActivityToday = await api_activityToday({ branch_id: branchRedux || "", time });
            if (resActivityToday.status) {
                let data = { ...resActivityToday };
                data.countCoursesOngoing = res.countCoursesOngoing;
                delete data.status;
                setRecapInfor({ ...recapInfor, ...data });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        _fetchRecapInfor();
        setLoading(false);
    }, [branchRedux]);

    const filterTabPane = (
        <Row
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            gutter={4}
        >
            {/* <Col>
                <Select
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    placeholder="Chọn tháng"
                    onChange={handleSourceMonth}
                >
                    {monthArray.map((item, i) => {
                        return (
                            <Option key={i} value={item}>
                                {`Tháng ${item}`}
                            </Option>
                        );
                    })}
                </Select>
            </Col> */}
            <Col>
                <DatePicker
                    style={
                        !isMobileScreen
                            ? { marginTop: 8, marginBottom: 5, width: "100%" }
                            : { marginBottom: 0, width: "100%" }
                    }
                    onOpenChange={(status) => {
                        if (status) {
                            setOpenSelectYear(true);
                        } else {
                            setOpenSelectYear(false);
                        }
                    }}
                    open={openSelectYear}
                    value={time}
                    mode="year"
                    format="YYYY"
                    onChange={(e) => {
                        setOpenSelectYear(false);
                        setTime(moment(d.getFullYear(), "YYYY"));
                    }}
                    onPanelChange={(v) => {
                        setOpenSelectYear(false);
                        setTime(v);
                    }}
                    allowClear={false}
                />
            </Col>
        </Row>
    );

    return (
        <>
            {console.log("222", recapInfor)}
            <Row className="card-pane" gutter={32}>
                <Col span={8} style={{ padding: 0 }}>
                    <Card className="content-card-info">
                        <Skeleton loading={loading} paragraph={false} className="skeleton-tv">
                            <Meta title={`${recapInfor && recapInfor.countCoursesOngoing} Khóa Học`} />
                        </Skeleton>
                    </Card>
                </Col>
                <Col span={8} style={{ paddingRight: 0 }}>
                    <Card className="content-card-info">
                        <Skeleton loading={loading} paragraph={false} className="skeleton-tv">
                            <Meta title={`${recapInfor && recapInfor.teacherBirthday} Sinh Nhật Giáo Viên`} />
                        </Skeleton>
                    </Card>
                </Col>
                <Col span={8} style={{ paddingRight: 0 }}>
                    <Card className="content-card-info">
                        <Skeleton loading={loading} paragraph={false} className="skeleton-tv">
                            <Meta title={`${recapInfor && recapInfor.studentBirthday} Sinh Nhật Học Viên`} />
                        </Skeleton>
                    </Card>
                </Col>
            </Row>
            {!isMobileScreen && filterTabPane}
            <Tabs defaultActiveKey="1" tabBarExtraContent={isMobileScreen && filterTabPane}>
                <TabPane tab="Học viên" key="1">
                    <LazyLoad delay={2200}>
                        <Student time={time} month={selectedMonth} />
                    </LazyLoad>
                </TabPane>
                <TabPane tab="Doanh thu" key="2">
                    <LazyLoad>
                        <Money time={time} month={selectedMonth} />
                    </LazyLoad>
                </TabPane>
            </Tabs>
        </>
    );
};

export default Dashboard;
