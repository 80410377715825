import React from "react";
import { Tag, Popover } from "antd";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";
import ConfigColumnsForTable from "@components/ConfigColumnsForTable";
import { numberWithCommas } from "@utils";
import moment from "moment";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, fixColumns, setConfigColumns, history, tableName } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/card");
        }
    };

    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            fixed: "left",
            width: 80,
            align: "center",
            key: "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Tên thẻ</span>,
            dataIndex: "type_card",
            width: 180,
            align: "center",
            key: "type_card",
            render: (text, record, index) => {
                return <span>{record.type_card || "Chưa có"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Giá trị</span>,
            dataIndex: "value",
            width: 150,
            align: "center",
            key: "value",
            render: (text, record, index) => {
                return <span>{record.value ? `${numberWithCommas(record.value)} VNĐ` : null}</span>;
            },
        },
        {
            title: <span className="dragHandler">Số buổi</span>,
            dataIndex: "number_lesson",
            width: 150,
            align: "center",
            key: "number_lesson",
            render: (text, record, index) => {
                return <span>{record.number_lesson ? `${record.number_lesson} buổi` : "Không giới hạn"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Các chi nhánh được áp dụng</span>,
            dataIndex: "branch_names",
            width: 300,
            align: "center",
            key: "branch_names",
            render: (text, record, index) => {
                return record?.course_names?.map((item, index) => (
                    <Tag style={{ marginBottom: 5 }} key={index} color="#108ee9">
                        {item}
                    </Tag>
                ));
            },
        },
        {
            title: <span className="dragHandler">Các loại môn được áp dụng</span>,
            dataIndex: "type_names",
            width: 300,
            align: "center",
            key: "type_names",
            render: (text, record, index) => {
                return record.type_names.map((item, index) => (
                    <Tag style={{ marginBottom: 5, fontSize: 14, fontWeight: 500 }} key={index} color="#108ee9">
                        {item}
                    </Tag>
                ));
            },
        },

        {
            title: <span className="dragHandler">Số lượng</span>,
            dataIndex: "expiration_value",
            width: 80,
            align: "center",
            key: "expiration_value",
            render: (text, record, index) => {
                return <span>{record.expiration_value}</span>;
            },
        },
        {
            title: <span className="dragHandler">Đơn vị tính</span>,
            dataIndex: "expiration",
            width: 120,
            align: "center",
            key: "expiration",
            render: (text, record, index) => {
                const dataObj = {
                    day: "Ngày",
                    week: "Tuần",
                    month: "Tháng",
                    year: "Năm",
                    course: "Cả khóa",
                };
                return <span>{dataObj[record.expiration]}</span>;
            },
        },
        // {
        //     title: "Giảm giá",
        //     dataIndex: "discount",
        //     width: 200,
        //     align: "center",
        //     key: "discount",
        //     render: (text, record, index) => {
        //         return <span>{record.discount ? `${record.discount}%`: "Chưa có giảm giá"}</span>;
        //     },
        // },
        {
            title: <span className="dragHandler">Trạng thái</span>,
            dataIndex: "active",
            width: 120,
            align: "center",
            key: "active",
            filters: [
                { text: "Đang mở", value: 1 },
                { text: "Khóa", value: 0 },
            ],
            filterMultiple: false,
            render: (text, record, index) => {
                return <span>{record.active ? "Đang mở" : "Khóa"}</span>;
            },
        },
        {
            title: <span className="dragHandler">Ngày tạo</span>,
            dataIndex: "created_at",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
