import React from "react";
import moment from "moment";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";

import { Image, Popover } from "antd";
import ConfigColumnsForTable from "@components/ConfigColumnsForTable";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, fixColumns, setConfigColumns, history, tableName } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/student");
        }
    };

    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            key: "stt",
            width: 70,
            fixed: "left",
            align: "center",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Ảnh học viên</span>,
            key: "image",
            dataIndex: "image",
            width: 130,
            align: "center",
            render: (text, record, index) => {
                return text ? <Image src={text} style={{ width: 75, height: "auto" }} /> : <div>Chưa có</div>;
            },
        },
        {
            key: "name",
            title: <span className="dragHandler">Họ tên học viên</span>,
            dataIndex: "name",
            width: 170,
            align: "center",
        },
        {
            key: "birthday",
            title: <span className="dragHandler">Sinh nhật</span>,
            dataIndex: "birthday",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.birthday ? moment(record.birthday, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
        {
            key: "email",
            title: <span className="dragHandler">Email</span>,
            dataIndex: "email",
            width: 170,
            align: "center",
        },
        {
            key: "phone",
            title: <span className="dragHandler">Số ĐT</span>,
            dataIndex: "phone",
            width: 120,
            align: "center",
        },
        {
            key: "gender",
            title: <span className="dragHandler">Giới tính</span>,
            dataIndex: "gender",
            width: 100,
            align: "center",
            render: (text, record) => <React.Fragment>{(text == 0 && "Nữ") || (text == 1 && "Nam")}</React.Fragment>,
        },
        // {
        // key: "address",
        //     title: <span className="dragHandler">Địa chỉ</span>,
        //     dataIndex: "address",
        //     width: 200,
        //     align: "center",
        // },
        {
            key: "note",
            title: <span className="dragHandler">Ghi chú</span>,
            dataIndex: "note",
            width: 200,
            align: "center",
        },
        {
            key: "created_at",
            title: "Ngày tạo",
            dataIndex: "created_at",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];
    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
