import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Button, Select, Row, Col } from "antd";
import moment from "moment";
import ReactExport from "react-data-export";

import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { saveBranch } from "@actions/branch";
import { removeAcl } from "@actions/acl";
import { getUserInfo } from "@utils/auth";
import { toArray } from "@utils";

import {
    api_getAllBranch,
    api_getAllBranchOptions,
    api_getAllTeacher,
    api_getAllStudent,
    api_getAllCourses,
    api_getAllInvoices,
    api_getAllTypeOfClass,
    api_getAllCards,
    api_getAllPromotions,
} from "@api/globalApi";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let { Option } = Select;
function Header(props) {
    const { history } = props;
    const { Header } = Layout;
    const [dataTeacher, setDataTeacher] = useState([]);
    const [dataStudent, setDataStudent] = useState([]);
    const [dataCourses, setDataCourses] = useState([]);
    const [dataInvoices, setDataInvoices] = useState([]);
    const [dataBranch, setDataBranch] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [dataCards, setDataCards] = useState([]);
    const [listBranch, setBranch] = useState([]);
    const [listPromotions, setListPromotions] = useState([]);

    const dispatch = useDispatch();
    let user = getUserInfo();

    useEffect(() => {
        if (user.branch_id) {
            dispatch(saveBranch({ branch_id: user.branch_id }));
        }
        const _getAllBranchOptions = async () => {
            const res = await api_getAllBranchOptions({
                _orderBy: "id:asc",
            });
            if (res.status) {
                if (res.data) {
                    let arr = toArray(res.data);
                    setBranch(arr);
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranchOptions();
        _getListBranch();
        _getListTeacher();
        _getListStudent();
        _getListCourses();
        _getListInvoices();
        _getListAllTypeOfClass();
        _getListCard();
        _getAllPromotions();
    }, []);

    const _getAllPromotions = async () => {
        const res = await api_getAllPromotions({ _paging: 0 });
        if (res.status) {
            setListPromotions(res.data);
        }
    };

    const _getListCard = async () => {
        const res = await api_getAllCards({ _paging: 0 });
        if (res.status) {
            setDataCards(res.data);
        }
    };

    const _getListBranch = async () => {
        const res = await api_getAllBranch({ _paging: 0 });
        if (res.status) {
            setDataBranch(res.data);
        }
    };

    const _getListAllTypeOfClass = async () => {
        const res = await api_getAllTypeOfClass({ paging: 0 });
        if (res.status) {
            setListTypeOfClass(res.data);
        }
    };

    const _renderExportListBranch = () => {
        return (
            <ExcelSheet data={dataBranch} name="Chi nhánh">
                <ExcelColumn label="Tên chi nhánh" value="name" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Số sân thuê" value="number_yard" />
                <ExcelColumn label="Trạng thái" value={(col) => (col.active == 1 ? "Đang mở" : "Đã đóng")} />
            </ExcelSheet>
        );
    };

    const _renderExportListPromotions = () => {
        return (
            <ExcelSheet data={listPromotions} name="Khuyến mại">
                <ExcelColumn label="Chương trình khuyến mại" value="name" />
                <ExcelColumn label="Mô tả" value="description" />
                <ExcelColumn label="Giá trị" value="value" />
                <ExcelColumn label="Đơn vị tính" value={(col) => (col.check_value ? "%" : "VNĐ")} />
                <ExcelColumn label="Số lượt sử dụng tối đa" value="count_use" />
                <ExcelColumn label="Số lượt sử dụng hiện tại" value="count_user_used" />
                <ExcelColumn label="Ngày áp dụng" value="begin_date" />
                <ExcelColumn label="Ngày kết thúc" value="end_date" />
                <ExcelColumn label="Trạng thái" value={(col) => (col.active ? "Đang mở" : "Khóa")} />
                <ExcelColumn label="Ngày tạo" value="created_at" />
            </ExcelSheet>
        );
    };

    const _getListCourses = async () => {
        const res = await api_getAllCourses({ paging: 0 });
        if (res.status) {
            setDataCourses(res.data);
        }
    };

    const _renderExportListCourses = () => {
        return (
            <ExcelSheet data={dataCourses} name="Khoá học">
                <ExcelColumn label="Tên khoá học" value="name" />
                <ExcelColumn label="Học phí" value="tuition" />
                <ExcelColumn
                    label="Loại lớp"
                    value={(col) => {
                        let filtedValue = listTypeOfClass.find((item) => item.id === col.type_id);
                        return filtedValue.name || "Không xác định";
                    }}
                />
                <ExcelColumn label="Số buổi học" value={(col) => (col.duration ? col.duration : "Linh động")} />
                <ExcelColumn
                    label="Giáo viên"
                    value={(col) => {
                        let teacher = dataTeacher.filter((item, index) => {
                            return item.id == col.teacher_id;
                        });
                        if (teacher[0]) {
                            return teacher[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn
                    label="Thứ 2"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_1.start_time && col.day_1.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_1.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_1.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 3"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_2.start_time && col.day_2.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_2.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_2.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 4"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_3.start_time && col.day_3.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_3.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_3.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 5"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_4.start_time && col.day_4.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_4.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_4.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 6"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_5.start_time && col.day_5.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_5.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_5.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 7"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_6.start_time && col.day_6.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_6.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_6.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Chủ nhật"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_7.start_time && col.day_7.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_7.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_7.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
            </ExcelSheet>
        );
    };

    const _getListTeacher = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setDataTeacher(res.data);
        }
    };

    const _renderExportListTeacher = () => {
        return (
            <ExcelSheet data={dataTeacher} name="Giáo viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender == 1 ? "Nam" : "Nữ")} />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Năm sinh" value="born_year" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn label="Hình ảnh" value="image" />
            </ExcelSheet>
        );
    };

    const _getListStudent = async () => {
        const res = await api_getAllStudent({ paging: 0 });
        if (res.status) {
            setDataStudent(res.data);
        }
    };

    const _renderExportListStudent = () => {
        return (
            <ExcelSheet data={dataStudent} name="Học viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn
                    label="Khóa học"
                    value={(col) => {
                        let data = [];
                        if (col.courses_name.length > 0) {
                            col.courses_name.map((item) => {
                                data.push(item.name);
                            });
                            return data.join(", ");
                        }
                        return "";
                    }}
                />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender == 1 ? "Nam" : "Nữ")} />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Ngày sinh" value="birthday" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn label="Hình ảnh" value="image" />
            </ExcelSheet>
        );
    };

    const _getListInvoices = async () => {
        const res = await api_getAllInvoices({ paging: 0 });
        if (res.status) {
            setDataInvoices(res.data);
        }
    };

    const _renderExportListInvoices = () => {
        return (
            <ExcelSheet data={dataInvoices} name="Hoá đơn">
                <ExcelColumn label="Mã hoá đơn" value="code" />
                <ExcelColumn label="Họ tên học viên" value="name" />
                <ExcelColumn label="Số tiền" value="amount" />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Loại hoá đơn"
                    value={(col) => {
                        if (col.type == 1) {
                            return "Thu";
                        }
                        if (col.type == 0) {
                            return "Chi";
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Số điện thoại học viên" value="phone" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Nội dung" value="content" />
                <ExcelColumn label="Ghi chú" value="note" />
            </ExcelSheet>
        );
    };

    const _renderExportListCards = () => {
        return (
            <ExcelSheet data={dataCards} name="Thẻ">
                <ExcelColumn label="Tên thẻ" value="type_card" />
                <ExcelColumn label="Giá trị" value="value" />
                <ExcelColumn label="Số buổi" value="number_lesson" />
                {/* <ExcelColumn label="Các khóa học được áp dụng" value="course_names" /> */}
                <ExcelColumn label="Trạng thái" value={(col) => (col.active ? "Đang mở" : "Khóa")} />
                <ExcelColumn label="Ngày tạo" value="created_at" />
            </ExcelSheet>
        );
    };

    const redirectTo = (route) => {
        props.history.replace(route);
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <a onClick={() => redirectTo("/profile")}>Thiết lập</a>
            </Menu.Item>
            <Menu.Item onClick={() => logOut()}>Đăng xuất</Menu.Item>
        </Menu>
    );

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        localStorage.removeItem("roles");
        localStorage.removeItem("acl");
        dispatch(saveBranch({ branch_id: null }));
        dispatch(removeAcl());
        history.push("/login");
    };

    const handleChangeType = (value) => {
        dispatch(saveBranch({ branch_id: value }));
        sessionStorage.clear();
    };

    return (
        <Header className="noPrint  layout-header">
            <Row className="container-header">
                <Col className="wrap-branch" style={{ display: "flex", alignItems: "center" }}>
                    <Select
                        defaultValue={user.branch_id || ""}
                        onChange={handleChangeType}
                        placeholder="Chọn chi nhánh"
                        style={{ marginLeft: 10, width: 200 }}
                    >
                        <Option key="2000" value="">
                            Tất cả chi nhánh
                        </Option>
                        {listBranch &&
                            listBranch.map((item, i) => {
                                return (
                                    <Option key={i} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                    </Select>
                    {/* <Button style={{ marginLeft: 10 }}>Thống kê</Button> */}
                    <div style={{ marginLeft: 10 }}>
                        <ExcelFile
                            element={<Button style={{ marginLeft: 10 }}>Xuất file toàn bộ</Button>}
                            filename="export_file"
                            fileExtension="xlsx"
                        >
                            {_renderExportListTeacher()}
                            {_renderExportListStudent()}
                            {_renderExportListCourses()}
                            {_renderExportListInvoices()}
                            {_renderExportListBranch()}
                            {_renderExportListCards()}
                            {_renderExportListPromotions()}
                        </ExcelFile>
                    </div>
                </Col>
                <Col style={{ padding: "0 24px" }}>
                    Xin chào, {user.username || user.name}
                    <Dropdown overlay={menu}>
                        <Button icon={<UserOutlined />} shape="circle-outline" className="icon-user" />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    );
}

export default Header;
