import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification } from "antd";
import api from "@api";
import moment from "moment";
import { DocTienBangChu } from "@utils/Num2String";
import { useComponentWillMount, numberWithCommas } from "@utils";
import { getRole, getUserInfo } from "@utils/auth";

function PrintInvoice(props) {
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceType, setInvoiceType] = useState("");
    const [invoiceCreator, setInvoiceCreator] = useState({});

    let user_info = getUserInfo();
    const handlePrintInvoice = () => {
        window.print();
    };

    useComponentWillMount(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 10,
        });
        let { params } = props.match;

        if (params.invoice_id) {
            api.get("/invoice/" + params.invoice_id, {
                params: {
                    _relations: "creator",
                },
            }).then(function (res) {
                if (res.data) {
                    let data = res.data;
                    if (res.data.creator.data) {
                        setInvoiceCreator(res.data.creator.data);
                    }
                    delete data.creator;
                    setInvoiceType(data.type);
                    setInvoiceData(data);
                }
            });
        }
    });

    return (
        <div>
            <div className="noPrint" style={{ marginBottom: 10, width: "fit-content" }}>
                <Button type="default" onClick={handlePrintInvoice}>
                    In hoá đơn
                </Button>
            </div>
            <div className="print-view-new">
                <p className="print-row dedive-2">
                    <div>
                        <span>
                            <b>BASIC CRM</b>
                        </span>
                        <span>357 Lê Hồng Phong, P.2, Q.10, Tp.Hồ Chí Minh</span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <span>Hotline: 19002615</span>
                        <span>(Số PT: {invoiceData.code})</span>
                    </div>
                </p>
                <div className="print-title">
                    <h3>{invoiceType == 1 ? "PHIẾU THU" : "PHIẾU CHI"}</h3>
                </div>

                <p className="print-row">
                    <span className="flex1 print-cell-60">
                        <b>Người {invoiceType == 1 ? "nộp" : "nhận"} tiền: </b>
                        {invoiceData.name}
                    </span>
                    <span className="flex1 print-cell-40">
                        <b>Số ĐT: </b>
                        {invoiceData.phone}
                    </span>
                </p>
                <p className="print-row">
                    <span>
                        <b>Nội dung {invoiceType == 1 ? "thu" : "chi"}: </b>
                        {invoiceData.content}
                    </span>
                </p>
                <p className="print-row">
                    <span>
                        <b>Số tiền: </b>
                        {invoiceData.amount && numberWithCommas(invoiceData.amount) + " VND"}
                        {invoiceData.amount && (
                            <span style={{ marginLeft: 10 }}>({DocTienBangChu(invoiceData.amount)})</span>
                        )}
                    </span>
                </p>
                <p className="print-row">
                    <span>
                        <b>HT thanh toán: </b>
                        {invoiceData.checkout_method == "tragop"
                            ? "Trả góp"
                            : invoiceData.checkout_method == "chuyenkhoan"
                            ? "Chuyển khoản"
                            : invoiceData.checkout_method == "quetthe"
                            ? "Quẹt thẻ"
                            : "Tiền mặt"}
                    </span>
                </p>
                <p className="print-row">
                    <span className="text-right">
                        Ngày {moment(invoiceData.created_at, "YYYY-MM-DD HH:mm:ss").format("DD")} tháng{" "}
                        {moment(invoiceData.created_at, "YYYY-MM-DD HH:mm:ss").format("MM")} năm{" "}
                        {moment(invoiceData.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY")}{" "}
                    </span>
                </p>
                <p className="print-signature">
                    <span className="item">
                        <b>Người lập phiếu</b>
                        <span>(Ký, họ tên)</span>
                        <span className="fullname">{invoiceData.creator ? invoiceData.creator : user_info.name}</span>
                    </span>
                    <span className="item">
                        <b>Người {invoiceType == 1 ? "nộp" : "nhận"} tiền</b>
                        <span>(Ký, họ tên)</span>
                        <span className="fullname"></span>
                    </span>
                    <span className="item">
                        <b>Thủ quỹ</b>
                        <span>(Ký, họ tên)</span>
                    </span>
                </p>
            </div>
        </div>
    );
}

export default PrintInvoice;
