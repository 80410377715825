import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, Modal, notification, Input, Breadcrumb } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";
import ContextMenu from "@components/ContextMenu";
import ModalComponent from "./Modal";
import { createColumnConfig } from "./ColumnsCard";
import { getFilterString, getHeightOfMainContent } from "@utils";
import { api_removeCard, api_getAllStudentMemberShipCards } from "./apiStudent";
import { api_getAllBranch } from "@api/globalApi";

let { Search } = Input;

const Card = (props) => {
    const { history } = props;
    const { student_id } = useParams();

    //data table
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [loading, setLoading] = useState(false);
    const [listBranch, setBranch] = useState([]);
    const [columns, setColumns] = useState([]);
    const [updateRow, setUpdateRow] = useState(false);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });
    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    //modal
    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xoá",
            async onOk() {
                try {
                    const res = await api_removeCard(record.id);
                    if (res.status == true) {
                        fetch(pagination.current, _, filterData);
                        notification.success({
                            message: "Thông báo",
                            description: "Xoá thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: "Xoá thất bại, vui lòng thử lại sau",
                        });
                    }
                } catch (err) {
                    notification.error({
                        message: "Cảnh báo",
                        description: "Xoá thất bại, vui lòng thử lại sau",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let newSorter = "id:desc";
        let newFilters = { ...filterData };
        if (Object.keys(sorter).length !== 0) {
            // fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
            newSorter = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
        }
        if (filters.active) {
            if ("active" in filters) {
                newFilters.active = filters.active[0];
            }
        }
        if (!branchRedux && filters.branch_id) {
            newFilters.branch_id = filters.branch_id[0] || "";
        } else {
            newFilters.branch_id = "";
        }
        setFilterData(newFilters);
        fetch(pagination.current, newSorter, newFilters);
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "", student_id };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        const filters = getFilterString(filterData);
        const params = {
            params: {
                _page: page,
                _perPage: 10,
                _orderBy: sort,
                _filter: filters,
                _relations: "student,creator",
            },
        };
        try {
            const res = await api_getAllStudentMemberShipCards(params);
            if (res.status) {
                const newPagination = { ...pagination };
                newPagination.total = res.meta.pagination.total;
                newPagination.pageSize = res.meta.pagination.per_page;
                newPagination.current = res.meta.pagination.current_page;
                setData(res.data);
                setPagination(newPagination);
                setLoading(false);
            } else {
                setData([]);
                setLoading(false);
            }
        } catch (err) {
            setData([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listBranch,
            branchRedux,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => onUpdateRow(record),
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xóa
                        </>
                    ),
                },
            ],
        });
    }, [listBranch, pagination, branchRedux]);

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    useEffect(() => {
        const _getAllBranch = async () => {
            const res = await api_getAllBranch();
            if (res.status) {
                if (res.data) {
                    setBranch(res.data);
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/course-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const createNew = () => {
        setDataModal({});
        openModal("create");
    };

    const onUpdateRow = (record, index) => {
        setDataModal(record, index);
        openModal("update");
    };

    const openModal = (type) => {
        setShowModal(true);
        if (type == "create") {
            setUpdateRow(false);
        } else if (type == "update") {
            setUpdateRow(true);
        }
    };

    return (
        <>
            <ModalComponent
                visible={showModal}
                isUpdate={updateRow}
                setShowModal={setShowModal}
                dataModal={dataModal}
                setDataModal={setDataModal}
                fetch={fetch}
                showModal={showModal}
                pagination={pagination}
                branchRedux={branchRedux}
                listBranch={listBranch}
                student_id={student_id}
                filterData={filterData}
            />
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="name-breadcrumb">Học viên</Breadcrumb.Item>
                <Breadcrumb.Item className="name-breadcrumb">
                    <Link to={"/student-card/" + student_id} className="is-link">
                        Thẻ thành viên
                    </Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Row type="flex" justify="center">
                <Col span={24}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <Search placeholder="Tìm kiếm tên thẻ" style={{ width: 250 }} onSearch={_handleSearchTable} />
                        <div>
                            <Button onClick={createNew} type="primary">
                                Thêm mới
                            </Button>
                        </div>
                    </div>
                    <Row style={{ marginBottom: 0 }}>
                        <Table
                            bordered
                            onRow={onRowTable}
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={handleTableChange}
                            scroll={{
                                y: getHeightOfMainContent(),
                            }}
                        />
                        <ContextMenu {...menuContext} />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Card;
