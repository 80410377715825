import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, Modal, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import api from "@api";
import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getFilterString, getHeightOfMainContent, toArray } from "@utils";
import { api_getAllPitch } from "./apiPitch";
import { api_getAllTeacher, api_getAllBranch, api_getAllBranchOptions } from "@api/globalApi";

let { Search } = Input;

const Pitch = (props) => {
    // const acl = useSelector((state) => state.acl.acl);

    const { history } = props;

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [listTeacher, setListTeacher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listBranch, setBranch] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xoá",
            onOk() {
                api.delete(`/pitch/${record.id}`).then(function (res) {
                    if (res.status == true) {
                        fetch();
                        notification.success({
                            message: "notify",
                            description: "Xoá thành công",
                        });
                    } else {
                        notification.error({
                            message: "warning",
                            description: "Xoá thất bại, vui lòng thử lại sau",
                        });
                    }
                });
            },
        });
    };

    const _getAllTeacher = async () => {
        const res = await api_getAllTeacher();
        if (res.status) {
            setListTeacher(res.data);
        }
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let newSorter = "id:desc";
        let newFilters = {};
        if (Object.keys(sorter).length !== 0) {
            // fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
            newSorter = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
        }
        if (!branchRedux && filters.branch_id) {
            newFilters = { branch_id: filters.branch_id[0] || "" };
        } else {
            newFilters = { branch_id: "" };
        }

        fetch(pagination.current, sorter, newFilters);
    };

    const _handleChangeGlobalBranch = () => {
        let newSorter = "id:desc";
        let paginationStorage = sessionStorage.getItem("pagination");
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filters = getFilterString(filterData);
        const res = await api_getAllPitch({ page, filters });
        setLoading(false);
        let newPagination = { ...pagination, ...res.meta.pagination, current: res.meta.pagination.current_page };
        setData(res.data);
        setPagination(newPagination);
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listTeacher,
            listBranch,
            branchRedux,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push(`/pitch-detail/${record.id}`);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Chỉnh sửa
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xoá
                        </>
                    ),
                },
            ],
        });
    }, [listBranch, pagination, branchRedux, listTeacher]);

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    useEffect(() => {
        const _getAllBranch = async () => {
            const res = await api_getAllBranchOptions();
            if (res.status) {
                if (res.data) {
                    setBranch(toArray(res.data));
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        _getAllTeacher();
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/pitch-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <Row type="flex" justify="center">
            <div></div>
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search placeholder="Tìm kiếm tên sân" style={{ width: 250 }} onSearch={_handleSearchTable} />
                    <Button onClick={() => history.push("/add-pitch")} type="primary">
                        Thêm mới
                    </Button>
                </div>
                <Row style={{ marginBottom: 0 }}>
                    <Table
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                            x: 1400,
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
};

export default Pitch;
