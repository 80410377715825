import Role from "@containers/Role";
import RoleDetail from "@containers/Role/Detail";
import Account from "@containers/Account";
import AccountDetail from "@containers/Account/Detail";
import Student from "@containers/Student";
import DetailStudent from "@containers/Student/Detail.js";
import StudentMoney from "@containers/Student/StudentMoneyTable";
import StudentCourse from "@containers/Student/Course.js";
import StudentCourseDetail from "@containers/Student/CourseDetail.js";
import StudentLesson from "@containers/Student/Lesson.js";
import StudentLessonDetail from "@containers/Student/LessonDetail.js";
import StudentCard from "@containers/Student/Card";
import Invoice from "@containers/Invoice";
import InvoiceDetail from "@containers/Invoice/Detail";
import Print from "@containers/Invoice/Print";
import Teacher from "@containers/Teacher";
import TeacherDetail from "@containers/Teacher/Detail";
import Dashboard from "@containers/Dashboard";
import Course from "@containers/Course";
import CourseDetail from "@containers/Course/Detail";
import Branch from "@containers/Branch";
import BranchDetail from "@containers/Branch/Detail";
import Profile from "@containers/Account/Profile";
import Pitch from "@containers/Pitch";
import PitchDetail from "@containers/Pitch/Detail";
import TypeOfClass from "@containers/TypeOfClass";
import Card from "@containers/Card";
import Promotion from "@containers/Promotion";

export const mainRoutes = [
    // {
    //     path: "/",
    //     component: Dashboard,
    //     exactly: true,
    // },
    {
        path: "/pitch",
        component: Pitch,
        exactly: true,
    },
    {
        path: "/add-pitch",
        component: PitchDetail,
        exactly: true,
    },
    {
        path: "/pitch-detail/:id",
        component: PitchDetail,
        exactly: true,
    },
    {
        path: "/dashboard",
        component: Dashboard,
        exactly: true,
    },
    {
        path: "/course-detail/:id",
        component: CourseDetail,
        exactly: true,
    },
    {
        path: "/course-detail",
        component: CourseDetail,
        exactly: true,
    },
    {
        path: "/course",
        component: Course,
        exactly: true,
    },
    {
        path: "/teacher-detail/:id",
        component: TeacherDetail,
        exactly: true,
    },
    {
        path: "/teacher-detail",
        component: TeacherDetail,
        exactly: true,
    },
    {
        path: "/teacher",
        component: Teacher,
        exactly: true,
    },
    {
        path: "/account-detail/:id",
        component: AccountDetail,
        exactly: true,
    },
    {
        path: "/add-account",
        component: AccountDetail,
        exactly: true,
    },
    {
        path: "/account",
        component: Account,
        exactly: true,
    },
    {
        path: "/role-detail/:role_id",
        component: RoleDetail,
        exactly: true,
    },
    {
        path: "/add-role",
        component: RoleDetail,
        exactly: true,
    },
    {
        path: "/role",
        component: Role,
        exactly: true,
    },
    {
        path: "/student-money",
        component: StudentMoney,
        exactly: true,
    },
    {
        path: "/student",
        component: Student,
        exactly: true,
    },
    {
        path: "/add-student",
        component: DetailStudent,
        exactly: true,
    },
    {
        path: "/student-detail/:student_id",
        component: DetailStudent,
        exactly: true,
    },
    {
        path: "/student-course/:student_id",
        component: StudentCourse,
        exactly: true,
    },
    {
        path: "/add-student-course/:student_id",
        component: StudentCourseDetail,
        exactly: true,
    },
    {
        path: "/update-student-course/:student_id/:course_id/:student_course_id",
        component: StudentCourseDetail,
        exactly: true,
    },
    // {
    //     path: "/student-card/:student_id",
    //     component: StudentCard,
    //     exactly: true,
    // },
    {
        path: "/student-lesson/:student_id/:course_id/:student_course_id",
        component: StudentLesson,
        exactly: true,
    },
    {
        path: "/update-student-lesson/:student_id/:course_id/:lesson_id",
        component: StudentLessonDetail,
        exactly: true,
    },
    {
        path: "/invoice",
        component: Invoice,
        exactly: true,
    },
    {
        path: "/create-invoice",
        component: InvoiceDetail,
        exactly: true,
    },
    {
        path: "/invoice-detail/:invoice_id",
        component: InvoiceDetail,
        exactly: true,
    },
    {
        path: "/print/:invoice_id",
        component: Print,
        exactly: true,
    },
    {
        path: "/branch",
        component: Branch,
        exactly: true,
    },
    {
        path: "/add-branch",
        component: BranchDetail,
        exactly: true,
    },
    {
        path: "/branch-detail/:branch_id",
        component: BranchDetail,
        exactly: true,
    },
    {
        path: "/profile",
        component: Profile,
        exactly: true,
    },
    {
        path: "/type-of-class",
        component: TypeOfClass,
        exactly: true,
    },
    {
        path: "/card",
        component: Card,
        exactly: true,
    },
    {
        path: "/promotion",
        component: Promotion,
        exactly: true,
    },
    // {
    //     path: "/",
    //     component: Role,
    //     exactly: true,
    // },
];
