import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, notification, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";

import { useHistory } from "react-router-dom";

import { getFilterString } from "@utils";
import { getListAccount } from "./apiAccount";
import "./index.scss";

//fixColumns
import { getLocalColumns } from "@utils";
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.account;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "name", value: "Họ tên" },
    { key: "username", value: "Username" },
    { key: "active", value: "Trạng thái" },
    { key: "role_name", value: "Quyền" },
];

let Search = Input.Search;

function Account() {
    //fixColumn
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    let history = useHistory();

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;

        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        const res = await getListAccount({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _filter: filter,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            setData(res.data);
            setPagination(newpagination);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push("/account-detail/" + record.id);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
            ],
        });
    }, [pagination, configColumns]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        fetch();
        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/account-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 10 }}>
                    <Search
                        placeholder="Tìm kiếm tài khoản"
                        style={{
                            width: 250,
                            marginBottom: 0,
                        }}
                        onSearch={(value) => handleSearch(value)}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push("/add-account");
                        }}
                    >
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <CustomTable
                    tableName={tableName}
                    bordered
                    onRow={onRowTable}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default Account;
