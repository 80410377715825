import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentVersion, showNewVersionPopup, useComponentWillMount } from "@utils";
import authHOC from "@utils/auth";
import { createAcl, saveAcl } from "@actions/acl";
import Config from "@config";
import api from "@api";
import { mainRoutes } from "@routes";
import "./index.scss";

import Sidebar from "./Sidebar";
import Header from "./Header";
const { Content } = Layout;

const Main = (props) => {
    const { history } = props;
    const [loading, setLoading] = useState(true);
    const auth = useSelector((state) => (state.auth ? state.auth : null));

    const dispatch = useDispatch();

    useComponentWillMount(() => {
        dispatch(createAcl());
        api.get("/role/me").then((res) => {
            if (res.status) {
                localStorage.setItem("acl", res.token);
                dispatch(saveAcl(res.token));
                setLoading(false);
            }
        });
    });

    useEffect(() => {
        if (Config.NODE_ENV == "production") {
            getCurrentVersion().then((hash) => {
                localStorage.setItem("hash_version", hash);
                const checkVersionItv = setInterval(() => {
                    getCurrentVersion().then((hash) => {
                        if (localStorage.getItem("hash_version") != hash) {
                            clearInterval(checkVersionItv);
                            showNewVersionPopup();
                        }
                    });
                }, 60000);
            });
        }
    }, []);

    return (
        <Layout className="main-container">
            <Sidebar />
            <Layout className="site-layout">
                <Header history={history} />
                <Content id="main-content" className="site-layout-background">
                    <Switch>
                        {mainRoutes.map((item, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={item.path}
                                    exact={item.exactly}
                                    component={authHOC(item.component)}
                                />
                            );
                        })}
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Main;
