import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Row, Col, notification } from "antd";

import { useHistory, useParams } from "react-router-dom";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "@components/CenterArea";

import { InputCurrency } from "@components/Input";
import { validateObject } from "@utils";
import { api_getOneBranch, api_createBranch, api_updateBranch } from "./apiBranch";
import { phoneValidation, numberValidation } from "@utils/validation";
import "./index.scss";

const Detail = () => {
    const [form] = NewForm.useForm();
    let history = useHistory();
    let { branch_id } = useParams();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                values.number_yard = parseFloat(values.number_yard);
                let dataSubmit = validateObject(values);
                if (branch_id) {
                    let res = await api_updateBranch(branch_id, dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi, vui lòng thử lại",
                        });
                    }
                    setLoading(false);
                } else {
                    let res = await api_createBranch(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        history.goBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi, vui lòng thử lại",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                // notification.error({
                //     message: "Cảnh báo",
                //     description: "Lỗi hệ thống",
                // });
                setLoading(false);
            });
    };

    const initFetch = async () => {
        const res = await api_getOneBranch(branch_id);
        if (res.status) {
            form.setFieldsValue({
                name: res.data.name,
                branch_id: res.data?.branch_id?.toString(),
                address: res.data.address,
                active: res.data.active,
                phone: res.data.phone,
                number_yard: res.data.number_yard?.toString(),
            });
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        if (branch_id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            form.setFieldsValue({
                active: true,
            });
            setVisible(true);
        }
        return () => {
            if (!history.location.pathname.includes("/branch")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                <FieldFloatItem
                    label="Tên chi nhánh"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập tên chi nhánh",
                        },
                    ]}
                >
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem label="Địa chỉ" name="address">
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem
                    rules={[
                        {
                            pattern: phoneValidation,
                            message: "Vui lòng nhập đủ 10 chữ số",
                        },
                    ]}
                    label="Số điện thoại liên hệ"
                    name="phone"
                >
                    <Input maxLength={10} />
                </FieldFloatItem>

                <FieldFloatItem
                    label="Số lượng phòng/sân tập"
                    name="number_yard"
                    rules={[
                        {
                            pattern: numberValidation,
                            message: "Vui lòng chỉ nhập số",
                        },
                    ]}
                >
                    <Input />
                </FieldFloatItem>
                <Form.Item name="active">
                    <Radio.Group>
                        <Radio value={true}>Mở</Radio>
                        <Radio value={false}>Đóng</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item>
                    <Row type="flex" style={{ marginTop: 20 }}>
                        <Col style={{ marginRight: 10 }}>
                            <Button type="default" onClick={() => history.push("/branch")}>
                                Quay lại
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" loading={loading} onClick={onSubmit}>
                                {branch_id ? "Cập nhật" : "Tạo mới"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </NewForm>
        </CenterArea>
    );
};

export default Detail;
