import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, notification, Row, Col, Select } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import PropTypes from "prop-types";
import { api_createTypeOfClass, api_updateTypeOfClass } from "./apiTypeOfClass";
import { api_getAllBranchOptions } from "@api/globalApi";
import { toArray } from "@utils";
import "./index.scss";

const { Option } = Select;
function ModalComponent(props) {
    const branchElRef = useRef();
    //define form instance
    const [form] = NewForm.useForm();
    //props
    const { fetch, visible, isUpdate, setShowModal, dataModal, showModal, pagination, setDataModal } = props;

    //state
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [listBranch, setListBranch] = useState([]);

    useEffect(() => {
        const _getAllBranch = async () => {
            let params = { params: { _orderBy: "id:asc" } };
            const res = await api_getAllBranchOptions(params);
            if (res.status) {
                if (res.data) {
                    setListBranch(toArray(res.data));
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranch();
        if (showModal) {
            console.log("2222222222", dataModal);
            form.setFieldsValue({
                branch_ids: isUpdate && dataModal.branch_ids ? dataModal.branch_ids : [],
                name: isUpdate ? dataModal.name : "",
            });
        }
    }, [showModal, isUpdate]);

    const handleCancel = () => {
        setDataModal({});
        setShowModal(false);
    };

    const onSubmitRow = () => {
        setConfirmLoading(true);
        form.validateFields()
            .then(async (values) => {
                const dataSubmit = { ...values };
                if (dataSubmit.branch_ids.includes("all")) {
                    dataSubmit.branch_ids = listBranch.map((item) => item.id);
                }
                dataSubmit.active = 1;
                if (isUpdate) {
                    const res = await api_updateTypeOfClass(dataModal.id, dataSubmit);
                    if (res.status) {
                        fetch(pagination.current);
                        setConfirmLoading(false);
                        setShowModal(false);
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Cập nhật thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                } else {
                    const res = await api_createTypeOfClass(dataSubmit);
                    if (res.status) {
                        fetch();
                        setConfirmLoading(false);
                        setShowModal(false);
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Tạo mới thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                }
            })
            .catch((err) => {
                setConfirmLoading(false);
                notification.error({
                    message: "Cảnh báo",
                    description: "Không thể gửi yêu cầu, vui lòng thử lại",
                });
            });
    };

    const handleChangeBranch = async (value) => {
        if (value.includes("all")) {
            let branchIdData = listBranch.map((item) => item.id);
            form.setFieldsValue({ branch_ids: branchIdData });
            branchElRef.current.blur();
            return;
        }
    };

    return (
        <div>
            <Modal
                centered
                title={isUpdate ? "Cập nhật loại lớp" : "Thêm loại lớp"}
                cancelText="Hủy"
                okText={isUpdate ? "Cập nhật" : "Lưu"}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                onOk={onSubmitRow}
            >
                <NewForm className="new-form modal-form" form={form}>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Chi nhánh được áp dụng"
                                name="branch_ids"
                                rules={[{ required: true, message: "Vui lòng chọn chi nhánh!" }]}
                            >
                                <Select
                                    // style={{ width: "100%" }}
                                    ref={branchElRef}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    onChange={handleChangeBranch}
                                >
                                    {listBranch.length > 0 && <Option value="all">Tất cả chi nhánh</Option>}
                                    {listBranch.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Loại lớp" name="name">
                                <Input />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                </NewForm>
            </Modal>
        </div>
    );
}

ModalComponent.propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ModalComponent;
