import api from "@api";

export const getStudentNumbers = (params) => {
    const url = "/statistic/count_student";
    return api.get(url, params);
};

export const api_getRevenue = (param) => {
    const url = `/statistic/growth`;
    return api.get(url, {
        params: {
            // _page: param.page,
            branch_id: param && param.branch_id,
            year: param?.time.format("YYYY"),
        },
    });
};
export const api_activityToday = (param) => {
    const url = `/statistic/count_activity_today`;
    return api.get(url, {
        params: {
            // _page: param.page,
            branch_id: param && param.branch_id,
            year: param?.time.format("YYYY"),
        },
    });
};
