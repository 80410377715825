import React from "react";
import {
    AreaChartOutlined,
    BookOutlined,
    ContactsOutlined,
    SafetyOutlined,
    SolutionOutlined,
    UserOutlined,
    BranchesOutlined,
    PullRequestOutlined,
    SnippetsOutlined,
    CreditCardOutlined,
    QrcodeOutlined,
} from "@ant-design/icons";

export default {
    menus: {
        dashboard: {
            key: 1,
            name: "Thống kê",
            icon: <AreaChartOutlined />,
            url: "/dashboard",
            resource: "statistic",
            general_menu: true,
        },
        student: {
            key: 2,
            name: "Học viên",
            icon: <UserOutlined />,
            url: "/student",
            resource: "student",
            general_menu: true,
        },
        // {
        //     key: 3,
        //     name: "Học viên cũ",
        //     icon: <UserDeleteOutlined />,
        //     url: "/old-student",
        //     resource: "oldstudent",
        //     general_menu: true,
        // },
        teacher: {
            key: 4,
            name: "Giáo viên",
            icon: <SolutionOutlined />,
            url: "/teacher",
            resource: "teacher",
            general_menu: true,
        },
        invoice: {
            key: 5,
            name: "Hoá đơn",
            icon: <SolutionOutlined />,
            url: "/invoice",
            resource: "invoice",
            general_menu: true,
        },
        type_of_class: {
            key: 6,
            name: "Loại lớp",
            url: "/type-of-class",
            icon: <SnippetsOutlined />,
            resource: "type_of_class",
            general_menu: true,
        },
        card: {
            key: 7,
            name: "Thẻ",
            url: "/card",
            icon: <CreditCardOutlined />,
            resource: "card",
            general_menu: true,
        },
        promotion_code: {
            key: 8,
            name: "Chương trình khuyến mãi",
            url: "/promotion",
            icon: <QrcodeOutlined />,
            resource: "promotion",
            general_menu: true,
        },
        course: {
            key: 9,
            name: "Khoá học",
            icon: <BookOutlined />,
            url: "/course",
            resource: "course",
            general_menu: true,
        },
        account: {
            key: 10,
            name: "Tài khoản",
            url: "/account",
            icon: <ContactsOutlined />,
            resource: "account",
            general_menu: true,
        },
        role: {
            key: 11,
            name: "Bảng phân quyền",
            url: "/role",
            icon: <SafetyOutlined />,
            resource: "role",
            general_menu: true,
        },
        branch: {
            key: 12,
            name: "Chi nhánh",
            url: "/branch",
            icon: <BranchesOutlined />,
            resource: "branch",
            general_menu: true,
        },
        // pitch: {
        //     key: 13,
        //     name: "Sân tập",
        //     url: "/pitch",
        //     icon: <PullRequestOutlined />,
        //     resource: "branch",
        //     general_menu: true,
        // },
    },
};
