import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, notification, Select, Row, Col, Spin, Radio, Form } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { InputCurrency } from "@components/Input";
import { api_createCards, api_updateCard, api_getBranchTypeOfClass } from "./apiCard";
import { api_getAllCourses, api_getAllBranchOptions } from "@api/globalApi";
import { numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { numberValidation } from "@utils/validation";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import "./index.scss";

const { Option } = Select;
function ModalComponent(props) {
    const branchElRef = useRef();
    const typeElRef = useRef();
    //props
    const { fetch, visible, isUpdate, setShowModal, dataModal, showModal, pagination } = props;

    //define form instance
    const [form] = NewForm.useForm();

    //state
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [isLimited, setIsLimited] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const [toEndCourse, setToEndCourse] = useState(false);
    const [listBranch, setListBranch] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [dataBranchtype, setDataBranchtype] = useState({});
    const [dataType, setDataType] = useState([]);

    useEffect(() => {
        fetchCourses();
        const _getAllBranch = async () => {
            let params = { params: { _orderBy: "id:asc" } };
            const res = await api_getAllBranchOptions(params);
            if (res.status) {
                if (res.data) {
                    setListBranch(toArray(res.data));
                }
            } else {
                console.log("err: ", res);
            }
        };
        const _getBranchTypeOfClass = async () => {
            const params = {
                _paging: 0,
                _filter: `branch_ids:${dataModal.branch_ids.join(",")}`,
                _relations: "branchs",
            };
            const resType = await api_getBranchTypeOfClass(params);
            if (resType.status) {
                setListTypeOfClass(resType.data);
            }
        };

        _getAllBranch();

        if (showModal && isUpdate) {
            _getBranchTypeOfClass();
            if (dataModal.branch_ids.length > 0) {
                let data = {};
                dataModal.branch_ids.map((item) => {
                    data[item] = [];
                });
                setDataBranchtype(data);
            } else {
                setDataBranchtype([]);
            }
            setDataType(dataModal.type_ids);
            setIsLimited(!!dataModal.number_lesson);
            form.setFieldsValue({
                type_card: dataModal.type_card || "",
                is_limited: !!dataModal.number_lesson,
                number_lesson: dataModal.number_lesson || "",
                value: dataModal.value ? numberWithCommas(dataModal.value) : "",
                active: !!dataModal.active,
                discount: dataModal.discount || "",
                expiration: dataModal.expiration || "",
                expiration_value: dataModal.expiration_value || null,
                branch_ids: dataModal.branch_ids,
                type_ids: dataModal.type_ids,
            });
        } else {
            setIsLimited(true);
            form.setFieldsValue({
                type_card: "",
                is_limited: true,
                value: "",
                duratrion: "",
                // courses: [],
                active: true,
                discount: "",
                expiration: "",
                // expiration_value: "",
            });
        }
    }, [showModal, isUpdate, dataModal]);

    const handleChangeBranch = async (value) => {
        let data = {};
        if (value.includes("all")) {
            listBranch.map((item) => {
                data[item.id] = [];
            });
            setDataBranchtype(data);
            let branchIdData = listBranch.map((item) => item.id);
            form.setFieldsValue({ branch_ids: branchIdData });
            branchElRef.current.blur();
            const res = await api_getBranchTypeOfClass({ branch_ids: branchIdData.join(",") });
            if (res.status) {
                setListTypeOfClass(res.data);
            } else {
                setListTypeOfClass([]);
            }
            return;
        }
        if (value && value.length > 0) {
            value.map((item) => {
                data[item] = [];
            });
            setDataBranchtype(data);
            try {
                setDataType([]);
                form.setFieldsValue({ type_ids: [] });
                const res = await api_getBranchTypeOfClass({ branch_ids: value.join(",") });
                if (res.status) {
                    setListTypeOfClass(res.data);
                } else {
                    setListTypeOfClass([]);
                }
            } catch (err) {
                setListTypeOfClass([]);
            }
        } else {
            setDataBranchtype(data);
            setDataType([]);
            setListTypeOfClass([]);
        }
    };
    const handleChangeType = async (value) => {
        if (value.includes("all")) {
            let typeIdData = listTypeOfClass.map((item) => item.id);
            form.setFieldsValue({ type_ids: typeIdData });
            setDataType(typeIdData);
            typeElRef.current.blur();
            return;
        }
        if (value && value.length > 0) {
            setDataType(value);
        } else {
            setDataType([]);
        }
    };

    const fetchCourses = async (value) => {
        try {
            setFetching(true);
            const param = {
                _page: 1,
                _filter: `slug:${value};`,
            };
            const res = await api_getAllCourses(param);
            if (res.status) {
                setFetching(false);
                setCoursesList(res.data);
            } else {
                setFetching(false);
                setCoursesList([]);
            }
        } catch (err) {
            setFetching(false);
            setCoursesList([]);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setShowModal(false);
    };

    const onSubmitRow = () => {
        setConfirmLoading(true);
        form.validateFields()
            .then(async (values) => {
                const dataSubmit = { ...values };
                //type vs branch
                delete dataSubmit.branch_ids;
                delete dataSubmit.type_ids;
                let dataBranch = _dataSubmitBranch();
                for (const [key, value] of Object.entries(dataBranch)) {
                    if (value.length == 0) {
                        delete dataBranch[key];
                    }
                }
                dataSubmit.branch_ids = dataBranch;

                dataSubmit.discount = parseInt(dataSubmit.discount) || null;
                dataSubmit.number_lesson = is_limited ? parseInt(dataSubmit.number_lesson) : null;
                dataSubmit.value = parseInt(numberWithoutCommas(dataSubmit.value));
                dataSubmit.expiration_value = parseInt(+dataSubmit.expiration_value);
                delete dataSubmit.is_limited;
                if (isUpdate) {
                    const res = await api_updateCard(dataModal.id, dataSubmit);
                    if (res.status) {
                        fetch(pagination.current);
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Cập nhật thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                } else {
                    const res = await api_createCards(dataSubmit);
                    if (res.status) {
                        fetch();
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Tạo mới thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                }
            })
            .catch((err) => {
                setConfirmLoading(false);
                console.log(err);
                notification.error({
                    message: "Cảnh báo",
                    description: "Không thể gửi yêu cầu, vui lòng thử lại",
                });
            });
    };

    const handleChangeValue = (e) => {
        form.setFieldsValue({ value: e.target.value ? numberWithCommas(e.target.value) : "" });
    };

    const _dataSubmitBranch = () => {
        let result = { ...dataBranchtype };
        if (listTypeOfClass.length > 0) {
            listTypeOfClass.map((item) => {
                item.branchs.map((i) => {
                    for (const [key, value] of Object.entries(result)) {
                        if (key == i.id) {
                            let data = result[key];
                            if (!data.includes(item.id) && dataType.includes(item.id)) {
                                data.push(item.id);
                                result[key] = data;
                            }
                        }
                    }
                });
            });
        }
        return result;
    };

    return (
        <div>
            <Modal
                centered
                title={isUpdate ? "Cập nhật thẻ" : "Thêm thẻ"}
                cancelText="Hủy"
                okText={isUpdate ? "Cập nhật" : "Lưu"}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                onOk={onSubmitRow}
            >
                <NewForm className="new-form modal-form" form={form}>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Tên thẻ"
                                name="type_card"
                                rules={[{ required: true, message: "Vui lòng nhập tên thẻ!" }]}
                            >
                                <Input />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem label="Số buổi" name="is_limited">
                                <Select style={{ height: 34 }} onChange={(value) => setIsLimited(value)}>
                                    <Option value={false}>Không giới hạn</Option>
                                    <Option value={true}>Có giới hạn</Option>
                                </Select>
                            </FieldFloatItem>
                        </Col>
                        {isLimited && (
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Nhập số buổi"
                                    name="number_lesson"
                                    rules={[
                                        { required: true, message: "Vui lòng nhập số buổi!" },
                                        {
                                            pattern: numberValidation,
                                            message: "Vui lòng chỉ nhập số",
                                        },
                                    ]}
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Giá trị" name="value">
                                <InputCurrency onChange={handleChangeValue} />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Chi nhánh được áp dụng"
                                name="branch_ids"
                                rules={[{ required: true, message: "Vui lòng chọn chi nhánh!" }]}
                            >
                                <Select
                                    ref={branchElRef}
                                    style={{ width: "100%" }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    onChange={handleChangeBranch}
                                    // onSearch={debounce(fetchCourses, 250)}
                                >
                                    <Option value="all">Tất cả chi nhánh</Option>
                                    {listBranch.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Loại môn được áp dụng"
                                name="type_ids"
                                rules={[{ required: true, message: "Vui lòng chọn Loại môn!" }]}
                            >
                                <Select
                                    ref={typeElRef}
                                    style={{ width: "100%" }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    onChange={handleChangeType}
                                >
                                    {listTypeOfClass.length > 0 && <Option value="all">Tất cả loại</Option>}
                                    {listTypeOfClass.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    {/* <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Các khóa học được áp dụng"
                                name="course_ids"
                                rules={[{ required: true, message: "Vui lòng chọn các khóa học!" }]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    // onSearch={debounce(fetchCourses, 250)}
                                >
                                    <Option value="all">Tất cả các khóa</Option>
                                    {coursesList.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row> */}

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Giảm giá (%)" name="discount">
                                <Input />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Hiệu lực (Đơn vị tính)"
                                name="expiration"
                                rules={[{ required: true, message: "Vui lòng chọn đơn vị tính!" }]}
                            >
                                <Select onChange={(value) => setToEndCourse(value === "course")}>
                                    <Option value="day">Ngày</Option>
                                    <Option value="week">Tuần</Option>
                                    <Option value="month">Tháng</Option>
                                    <Option value="year">Năm</Option>
                                    <Option value="course">Cả khóa</Option>
                                </Select>
                            </FieldFloatItem>
                        </Col>
                        {toEndCourse || (
                            <Col xs={24} sm={24} md={12}>
                                <FieldFloatItem
                                    label="Hiệu lực (Số lượng)"
                                    name="expiration_value"
                                    rules={[
                                        { required: true, message: "Vui lòng nhập số lượng!" },
                                        {
                                            pattern: numberValidation,
                                            message: "Vui lòng chỉ nhập số",
                                        },
                                    ]}
                                >
                                    <Input />
                                </FieldFloatItem>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <Form.Item label="Trạng thái" name="active">
                                <Radio.Group>
                                    <Radio value={true}>Đang mở</Radio>
                                    <Radio value={false}>Khóa</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </NewForm>
            </Modal>
        </div>
    );
}

ModalComponent.propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    dataModal: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
};

export default ModalComponent;
