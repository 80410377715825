import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Modal, notification } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";

import { getHeightOfMainContent } from "@utils";
import { getFilterString } from "@utils";

import { api_getAllBranch } from "@api/globalApi";
import { api_getDeleteBranch } from "./apiBranch";
import { ExportExcelBranch } from "@components/ExportExcel";

//fixColumns
import { getLocalColumns } from "@utils";
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.branch;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "name", value: "Tên chi nhánh" },
    { key: "address", value: "Địa chỉ" },
    { key: "number_yard", value: "Số sân thuê" },
    { key: "active", value: "Trạng thái" },
];

function BranchComponent() {
    //fixColumn
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    let history = useHistory();

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //  //filter
    //  const [filterData, setFilterData] = useStateCallback({});

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận đóng chi nhánh",
            async onOk() {
                let res = await api_getDeleteBranch(record.id);
                if (res.status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Đóng thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        const res = await api_getAllBranch({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _filter: filter,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            setData(res.data);
            setPagination(newpagination);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push("/branch-detail/" + record.id);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Đóng
                        </>
                    ),
                },
            ],
        });
    }, [pagination, configColumns]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        let paginationStorage = sessionStorage.getItem("pagination");
        // setColumns(newColumns);
        fetch(paginationStorage ? paginationStorage : 1);
        return () => {
            if (!history.location.pathname.includes("/branch-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <Row className="pne-controll" type="flex" justify="end">
                    {ExportExcelBranch()}
                    <Button type="primary" onClick={() => history.push("/add-branch")}>
                        Thêm mới
                    </Button>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <CustomTable
                        tableName={tableName}
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
}

export default BranchComponent;
