import React from "react";
import moment from "moment";
import { Popover } from "antd";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";

import ConfigColumnsForTable from "@components/ConfigColumnsForTable";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, fixColumns, setConfigColumns, history, tableName } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/role");
        }
    };
    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            key: "stt",
            width: 70,
            fixed: "left",
            align: "center",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Tên phân quyền</span>,
            key: "name",
            width: 170,
            dataIndex: "name",
            align: "left",
        },
        {
            title: <span className="dragHandler">Mô tả</span>,
            key: "description",
            width: 200,
            dataIndex: "description",
            align: "left",
        },
        {
            title: "Ngày tạo",
            key: "created_at",
            width: 120,
            dataIndex: "created_at",
            align: "left",
            render: (text, record) =>
                record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "",
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
