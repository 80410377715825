import api from "@api";

export const getUserInfo = async () => {
    const url = "/user/info";
    return api.get(url);
};

export const updateUserInfo = async (params) => {
    const url = "/user/change";
    return api.put(url, params);
};

export const getListAccount = async (params) => {
    const url = "/user";
    return api.get(url, {
        params,
    });
};

export const api_getListRole = (param) => {
    const url = `/role`;
    return api.get(url, { params: param });
};

export const createAccount = async (param) => {
    const url = "/user";
    return api.post(url, param);
};

export const updateAccount = async (user_id, param) => {
    const url = "/user/" + user_id;
    return api.put(url, param);
};

export const getAccount = async (user_id) => {
    const url = "/user/" + user_id;
    return api.get(url);
};
