import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Radio, Select, Upload, DatePicker, Row, Col, message, Tabs } from "antd";
import moment from "moment";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "@components/CenterArea";
import { api_createTeacher, api_getOneTeacher, api_updateTeacher } from "./apiTeacher";
import TabCourse from "./TabCourse";
import { api_uploadImage, api_deleteImage, api_getAllTypeOfClass } from "@api/globalApi";
import { phoneValidation, emailValidation } from "@utils/validation";
const { TabPane } = Tabs;

const { Option } = Select;
const Detail = (props) => {
    const typeElRef = useRef();

    const { id } = props.match.params;
    const { history } = props;
    const [form] = NewForm.useForm();

    const [listCourse, setListCourse] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [image, setImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);

    const onFinish = async (values) => {
        const sendData = async (imageurl) => {
            let dataSubmit = { ...values };
            dataSubmit.birthday = values.birthday ? moment(values.birthday).format("YYYY-MM-DD") : null;
            dataSubmit.image = imageurl ? imageurl : null;
            if (dataSubmit.class_type_ids.includes("all")) {
                dataSubmit.class_type_ids = listTypeOfClass.map((item) => item.id);
            }

            if (id) {
                const res = await api_updateTeacher({
                    id,
                    data: dataSubmit,
                });
                if (res.status) {
                    message.success("Cập nhật thành công");
                    history.push("/teacher");
                } else {
                    message.error(res.message || "Cập nhật thất bại, vui lòng thử lại sau");
                }
            } else {
                const res = await api_createTeacher(dataSubmit);
                if (res.status) {
                    message.success("Tạo thành công");
                    history.push("/teacher");
                } else {
                    message.error(res.message || "Tạo thất bại, vui lòng thử lại sau");
                }
            }
        };
        if (image) {
            if (typeof image !== "string") {
                const resImage = await api_uploadImage(image);
                if (resImage.status) {
                    sendData(resImage.result.file);
                }
            } else sendData(image);
        } else {
            if (oldImage) {
                api_deleteImage(oldImage);
            }
            sendData();
        }
    };

    const uploadButton = (
        <div>
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const _getAllTypeOfClass = async () => {
        try {
            const res = await api_getAllTypeOfClass({ paging: 0 });
            if (res.status) {
                setListTypeOfClass(res?.data);
            } else {
                setListTypeOfClass([]);
            }
        } catch (err) {
            setListTypeOfClass([]);
        }
    };

    const _getTeacher = async () => {
        const res = await api_getOneTeacher(id);
        if (res.status) {
            setListCourse(res.data.courses);
            setImage(res.data.image);
            const { name, email, birthday, gender, address, phone, status, class_type_ids, note } = res.data;
            form.setFieldsValue({
                name,
                email,
                birthday: birthday ? moment(birthday, "YYYY-MM-DD") : null,
                gender,
                address,
                phone,
                status,
                class_type_ids: class_type_ids,
                note,
            });
        }
    };

    useEffect(() => {
        _getAllTypeOfClass();
        if (id) {
            _getTeacher();
        } else {
            form.setFieldsValue({
                status: "1",
                gender: 1,
            });
        }
        return () => {
            if (!history.location.pathname.includes("/teacher")) {
                sessionStorage.clear();
            }
        };
    }, []);

    const _handleChangeImage = (info) => {
        let { file, fileList } = info;
        if (info.file.status === "uploading") {
            const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/gif" ||
                file.type === "image/x-icon";
            if (!isJpgOrPng) {
                message.error("Bạn chỉ upload ảnh định dạng JPG/PNG/GIF/ICO !");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error("Ảnh phải có kích thước nhỏ hơn 2MB!");
                return;
            }
        }
        //success
        if (fileList.length == 0) {
            if (typeof image === "string") {
                setOldImage(image);
            }
            setImage(null);
        } else {
            setImage(fileList[0]);
        }
    };

    const handleChangeType = async (value) => {
        if (value.includes("all")) {
            let typeIdData = listTypeOfClass.map((item) => item.id);
            form.setFieldsValue({ class_type_ids: typeIdData });
            typeElRef.current.blur();
            return;
        }
    };

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin giáo viên" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col xs={24} md={6}>
                                <Upload
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("Ok");
                                        }, 0);
                                    }}
                                    listType="picture-card"
                                    fileList={
                                        image
                                            ? image.uid
                                                ? [image]
                                                : [
                                                      {
                                                          uid: "1",
                                                          name: "image.png",
                                                          status: "done",
                                                          url: image,
                                                      },
                                                  ]
                                            : []
                                    }
                                    // onPreview={this.handlePreview}
                                    onChange={_handleChangeImage}
                                >
                                    {!image && uploadButton}
                                </Upload>
                            </Col>
                            <Col xs={24} md={18}>
                                <Row gutter={16}>
                                    <Col xs={24}>
                                        <FieldFloatItem
                                            rules={[{ required: true, message: "Nhập họ tên giáo viên!" }]}
                                            label="Họ tên giáo viên"
                                            name="name"
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập số điện thoại",
                                                },
                                                {
                                                    pattern: phoneValidation,
                                                    message: "Vui lòng nhập đủ 10 chữ số",
                                                },
                                            ]}
                                            label="Số điện thoại"
                                            name="phone"
                                        >
                                            <Input maxLength={10} />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Giới tính" name="gender">
                                            <Radio.Group>
                                                <Radio value={1}>Nam</Radio>
                                                <Radio value={0}>Nữ</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem label="Sinh nhật" name="birthday">
                                            <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <FieldFloatItem
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    pattern: emailValidation,
                                                    message: "Vui lòng nhập chính xác địa chỉ email",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Địa chỉ" name="address">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Loại môn học sẽ dạy" name="class_type_ids">
                                            <Select
                                                ref={typeElRef}
                                                style={{ width: "100%" }}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                    0
                                                }
                                                mode="multiple"
                                                onChange={handleChangeType}
                                            >
                                                {listTypeOfClass.length > 0 && <Option value="all">Tất cả loại</Option>}
                                                {listTypeOfClass.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Ghi chú" name="note">
                                            <Input.TextArea rows={3} />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <FieldFloatItem label="Trạng thái" name="status">
                                            <Select>
                                                <Select.Option value="1">Đang dạy</Select.Option>
                                                <Select.Option value="2">Đã nghỉ</Select.Option>
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                {id ? "Cập nhật" : "Tạo mới"}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {id && (
                <TabPane tab="Khoá học của giáo viên" key="2">
                    <TabCourse history={history} listCourse={listCourse} listTypeOfClass={listTypeOfClass} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default Detail;
