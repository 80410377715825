import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Modal, notification, Input } from "antd";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined, CalendarOutlined, CreditCardOutlined } from "@ant-design/icons";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";

import { useHistory } from "react-router-dom";

import { getFilterString, getLocalColumns } from "@utils";
import { getListStudent, deleteStudent } from "./apiStudent";
import "./index.scss";
import { ExportExcelStudent } from "@components/ExportExcel";

//fixColumns
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.student;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "image", value: "Hình ảnh" },
    { key: "name", value: "Họ tên" },
    { key: "birthday", value: "Sinh nhật" },
    { key: "email", value: "Email" },
    { key: "phone", value: "SDT" },
    { key: "gender", value: "Giới tính" },
    { key: "note", value: "Ghi chú" },
    { key: "created_at", value: "Ngày tạo" },
];

let Search = Input.Search;

function StudentComponent() {
    //fixColumns
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    let history = useHistory();
    const branchRedux = useSelector((state) => state.branch.branch_id);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xóa",
            async onOk() {
                let res = await deleteStudent(record.id);
                if (res.status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Xóa thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        const res = await getListStudent({
            _page: page,
            _perPage: 10,
            _orderBy: sort,
            _filter: filter,
        });
        if (res.status) {
            let newpagination = { ...pagination };
            newpagination.total = res.meta.pagination.total;
            newpagination.pageSize = res.meta.pagination.per_page;
            newpagination.current = res.meta.pagination.current_page;
            setData(res.data);
            setPagination(newpagination);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                // {
                //     showItem: () => true,
                //     onClick: (record) => {
                //         sessionStorage.setItem("pagination", pagination.current);
                //         history.push("/student-card/" + record.id);
                //     },
                //     render: () => (
                //         <>
                //             <CreditCardOutlined style={{ fontSize: 16, padding: 5 }} />
                //             Thẻ thành viên
                //         </>
                //     ),
                // },
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push("/student-course/" + record.id);
                    },
                    render: () => (
                        <>
                            <CalendarOutlined style={{ fontSize: 16, padding: 5 }} />
                            Khóa học
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push("/student-detail/" + record.id);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xóa
                        </>
                    ),
                },
            ],
        });
    }, [pagination, configColumns]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        // fetch();
        return () => {
            if (
                !history.location.pathname.includes("/student-detail") &&
                !history.location.pathname.includes("/student-course")
            ) {
                sessionStorage.clear();
            }
        };
    }, []);

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };
    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        if (branchRedux || branchRedux == "") {
            let newFilterData = { ...filterData };
            newFilterData.branch_id = branchRedux;
            setFilterData({ ...newFilterData });
            fetch(paginationStorage ? paginationStorage : 1, "id:desc", { ...newFilterData });
        } else {
            fetch(paginationStorage ? paginationStorage : 1);
        }
    };

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                <Col style={{ marginRight: 10 }}>
                    <Search
                        placeholder="Tìm kiếm thông tin học viên"
                        style={{
                            width: 250,
                            marginBottom: 0,
                        }}
                        onSearch={(value) => handleSearch(value)}
                    />
                </Col>
                <Col>
                    {ExportExcelStudent()}
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push("/add-student");
                        }}
                    >
                        Thêm mới
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <CustomTable
                    tableName={tableName}
                    bordered
                    onRow={onRowTable}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                        // x: 1300,
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default StudentComponent;
