import React from "react";
import moment from "moment";
import { numberWithCommas } from "@utils";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";

import { Popover } from "antd";
import ConfigColumnsForTable from "@components/ConfigColumnsForTable";

export const createColumnConfig = (configColumns, props) => {
    const {
        pagination,
        listTeacher,
        listBranch,
        branchRedux,
        listTypeOfClass,
        fixColumns,
        setConfigColumns,
        history,
        tableName,
    } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/course");
        }
    };

    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            key: "stt",
            width: 70,
            align: "center",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.per_page + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Tên khoá học</span>,
            key: "name",
            dataIndex: "name",
            width: 200,
            align: "center",
        },
        // {
        //     title: <span className="dragHandler">Học phí</span>,
        //     key: "tuition",
        //     dataIndex: "tuition",
        //     width: 170,
        //     align: "center",
        //     render: (_, record) => {
        //         return <span>{`${numberWithCommas(record.tuition)} VNĐ`}</span>;
        //     },
        // },
        // {
        //     title: <span className="dragHandler">Số buổi</span>,
        //     key: "duration",
        //     dataIndex: "duration",
        //     width: 120,
        //     align: "center",
        //     render: (text, record, index) => {
        //         return <div>{record.duration || "Linh động"}</div>;
        //     },
        // },
        {
            title: <span className="dragHandler">Giáo viên</span>,
            key: "teacher_id",
            dataIndex: "teacher_id",
            width: 150,
            align: "center",
            render: (text, record, index) => {
                let teacher = listTeacher && listTeacher.filter((item) => item.id == text);
                return <div>{teacher[0]?.name}</div>;
            },
        },
        {
            title: <span className="dragHandler">Loại lớp</span>,
            key: "type",
            dataIndex: "type",
            width: 150,
            align: "center",
            render: (text, record, index) => (
                <span>
                    {(listTypeOfClass.length && listTypeOfClass.find((item) => item.id == record.type_id)?.name) ||
                        "Cập nhật sau"}
                </span>
            ),
        },
        {
            title: <span className="dragHandler">Trạng thái</span>,
            key: "status",
            dataIndex: "status",
            width: 150,
            align: "center",
            render: (text, record, index) => {
                return <div>{text == "1" ? "Đang mở" : "Đã đóng"}</div>;
            },
        },
        {
            title: <span className="dragHandler">Chi nhánh</span>,
            key: "branch_id",
            dataIndex: "branch_id",
            width: 170,
            align: "center",
            render: (text) => {
                let branch = listBranch && listBranch.filter((item) => item.id == text);
                return <div>{branch[0]?.name}</div>;
            },
            filters: !branchRedux
                ? listBranch.map((item) => {
                      return { value: item.id, text: item.name };
                  })
                : null,
            filterMultiple: false,
        },
        {
            title: <span className="dragHandler">Ghi chú</span>,
            key: "note",
            dataIndex: "note",
            width: 200,
            align: "center",
        },
        {
            title: "Ngày tạo",
            key: "created_at",
            dataIndex: "created_at",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
