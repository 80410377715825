import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, Modal, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import api from "@api";
import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getFilterString, getHeightOfMainContent } from "@utils";
import { api_getAllCourse } from "./apiCourse";
import { api_getAllTeacher, api_getAllBranch, api_getAllTypeOfClass } from "@api/globalApi";
import { ExportExcelCourse } from "@components/ExportExcel";

//fixColumns
import { getLocalColumns } from "@utils";
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.course;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "name", value: "Tên khoá học" },
    // { key: "tuition", value: "Học phí" },
    // { key: "duration", value: "Số buổi" },
    { key: "teacher_id", value: "Giáo viên" },
    { key: "type", value: "Loại lớp" },
    { key: "status", value: "Trạng thái" },
    { key: "branch_id", value: "Chi nhánh" },
    { key: "note", value: "Ghi chú" },
    { key: "created_at", value: "Ngày tạo" },
];

let { Search } = Input;

const Course = (props) => {
    //fixColumns
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    // const acl = useSelector((state) => state.acl.acl);

    const { history } = props;

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [listTeacher, setListTeacher] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listBranch, setBranch] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xoá",
            onOk() {
                api.delete(`/course/${record.id}`).then(function (res) {
                    if (res.status == true) {
                        fetch();
                        notification.success({
                            message: "Thông báo",
                            description: "Xoá thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: "Xoá thất bại, vui lòng thử lại sau",
                        });
                    }
                });
            },
        });
    };

    const _getAllTeacher = async () => {
        try {
            const res = await api_getAllTeacher({ paging: 0 });
            if (res.status) {
                setListTeacher(res.data);
            } else {
                setListTeacher([]);
            }
        } catch (err) {
            setListTeacher([]);
        }
    };

    const _getAllTypeOfClass = async () => {
        try {
            const res = await api_getAllTypeOfClass({ paging: 0 });
            if (res.status) {
                setListTypeOfClass(res.data);
            } else {
                setListTypeOfClass([]);
            }
        } catch (err) {
            setListTypeOfClass([]);
        }
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let newSorter = "id:desc";
        let newFilters = {};
        if (Object.keys(sorter).length !== 0) {
            // fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
            newSorter = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
        }
        if (!branchRedux && filters.branch_id) {
            newFilters = { branch_id: filters.branch_id[0] || "" };
        } else {
            newFilters = { branch_id: "" };
        }

        fetch(pagination.current, sorter, newFilters);
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filters = getFilterString(filterData);
        try {
            const res = await api_getAllCourse({ page, filters });
            if (res.status) {
                setLoading(false);
                let newPagination = {
                    ...pagination,
                    ...res.meta.pagination,
                    current: res.meta.pagination.current_page,
                };
                setData(res.data);
                setPagination(newPagination);
            } else {
                setData([]);
                setLoading(false);
            }
        } catch (err) {
            setData([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            listTeacher,
            listBranch,
            branchRedux,
            listTypeOfClass,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push(`/course-detail/${record.id}`);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Chỉnh sửa
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xoá
                        </>
                    ),
                },
            ],
        });
    }, [listBranch, pagination, branchRedux, listTeacher, listTypeOfClass, configColumns]);

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        const _getAllBranch = async () => {
            const res = await api_getAllBranch();
            if (res.status) {
                if (res.data) {
                    setBranch(res.data);
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        _getAllTeacher();
        _getAllTypeOfClass();

        // setColumns(newColumns);
        return () => {
            if (!history.location.pathname.includes("/course-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Search placeholder="Tìm kiếm tên khóa học" style={{ width: 250 }} onSearch={_handleSearchTable} />
                    <div>
                        {ExportExcelCourse()}
                        <Button onClick={() => history.push("/course-detail")} type="primary">
                            Thêm mới
                        </Button>
                    </div>
                </div>
                <Row style={{ marginBottom: 0 }}>
                    <CustomTable
                        tableName={tableName}
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                            // x: 1400,
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
};

export default Course;
