import React, { useState, useEffect } from "react";
import { Row, Table, Modal, notification, Input } from "antd";
import { useSelector } from "react-redux";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";

import { useHistory } from "react-router-dom";

import { getFilterString } from "@utils";
import { getListStudent, deleteStudent } from "./apiStudent";
import { api_getListStudentMoney } from "@api/globalApi";

import "./index.scss";

let Search = Input.Search;

function StudentComponent() {
    let history = useHistory();

    const branchRedux = useSelector((state) => state.branch.branch_id);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [data, setData] = useState([]);
    const [countStudent, setCountStudent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false });

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xóa",
            async onOk() {
                let res = await deleteStudent(record.id);
                if (res.status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Xóa thành công",
                    });
                } else {
                    notification.error({
                        message: "Cảnh báo",
                        description: res.message || "Lỗi hệ thống",
                    });
                }
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);
        if (Object.keys(sorter).length !== 0) {
            fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
        } else {
            fetch(pagination.current);
        }
    };

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        const res = await api_getListStudentMoney(filterData);
        if (res.status) {
            setData(res.data);
            setCountStudent(res.countRemindStudentsToPay);
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
        });
        setColumns(columnConfig);
    }, [pagination]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        // fetch();
        // setColumns(newColumns);
    }, []);

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };
    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    return (
        <div className="pn-container-student">
            <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
                {/* <Col style={{ marginRight: 10 }}>
                    <Search
                        placeholder="Tìm kiếm tên học viên"
                        style={{
                            width: 250,
                            marginBottom: 0,
                        }}
                        onSearch={(value) => handleSearch(value)}
                    />
                </Col> */}
                <div>Tổng số học viên cần đóng tiền: {countStudent}</div>
            </Row>
            <Row style={{ marginBottom: 0 }}>
                <Table
                    bordered
                    // onRow={onRowTable}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={null}
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{
                        y: getHeightOfMainContent(),
                        x: 1300,
                    }}
                />
                <ContextMenu {...menuContext} />
            </Row>
        </div>
    );
}

export default StudentComponent;
