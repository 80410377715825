import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Table, Modal, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import api from "@api";
import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsConfig";
import { getFilterString, getHeightOfMainContent } from "@utils";
import { api_getAllTeacher, api_getAllTypeOfClass } from "@api/globalApi";
import { ExportExcelTeacher } from "@components/ExportExcel";

//fixColumns
import { getLocalColumns } from "@utils";
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.teacher;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "image", value: "Hình ảnh" },
    { key: "name", value: "Họ tên" },
    { key: "class_type_names", value: "Loại môn dạy" },
    { key: "gender", value: "Giới tính" },
    { key: "email", value: "Email" },
    { key: "phone", value: "SDT" },
    { key: "address", value: "Địa chỉ" },
    { key: "status", value: "Trạng thái" },
    { key: "note", value: "Ghi chú" },
    { key: "created_at", value: "Ngày tạo" },
];

let { Search } = Input;

const Teacher = (props) => {
    // const acl = useSelector((state) => state.acl.acl);

    const { history } = props;

    //fixColumn
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });
    const branchRedux = useSelector((state) => state.branch.branch_id);

    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });
    const [listTypeOfClass, setListTypeOfClass] = useState([]);

    const onShowDeleteRow = (record) => {
        Modal.confirm({
            title: "Xác nhận xoá",
            onOk() {
                api.delete(`/teacher/${record.id}`).then(function (res) {
                    if (res.status == true) {
                        fetch();
                        notification.success({
                            message: "notify",
                            description: "Xoá thành công",
                        });
                    } else {
                        notification.error({
                            message: "warning",
                            description: "Xoá thất bại, vui lòng thử lại sau",
                        });
                    }
                });
            },
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        let sortQuery = "";
        let newFilterData = { ...filterData };
        if (Object.keys(sorter).length !== 0) {
            sortQuery = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
            fetch(pagination.current, sortQuery);
        }
        if (Object.keys(filters).length !== 0) {
            if (filters.class_type_ids && filters.class_type_ids.length > 0) {
                newFilterData.class_type_ids = filters.class_type_ids;
            } else {
                delete newFilterData.class_type_ids;
            }
        }
        setFilterData(newFilterData);
        fetch(pagination.current, sortQuery, newFilterData);
    };

    const _handleChangeGlobalBranch = () => {
        let paginationStorage = sessionStorage.getItem("pagination");
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const _getAllTypeOfClass = async () => {
        try {
            const res = await api_getAllTypeOfClass({ paging: 0 });
            if (res.status) {
                setListTypeOfClass(res?.data);
            }
        } catch (err) {
            setListTypeOfClass([]);
        }
    };

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    const fetch = async (page = 0, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filters = getFilterString(filterData);
        const res = await api_getAllTeacher({ page, filters });
        setLoading(false);
        let newPagination = { ...pagination, ...res.meta.pagination, current: res.meta.pagination.current_page };
        setPagination(newPagination);
        setData(res.data);
    };

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
            listTypeOfClass,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        sessionStorage.setItem("pagination", pagination.current);
                        history.push(`/teacher-detail/${record.id}`);
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Chỉnh sửa
                        </>
                    ),
                },
                {
                    showItem: () => true,
                    onClick: (record) => onShowDeleteRow(record),
                    render: () => (
                        <>
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xoá
                        </>
                    ),
                },
            ],
        });
    }, [pagination, configColumns]);

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        _getAllTypeOfClass();
        let paginationStorage = sessionStorage.getItem("pagination");
        if (branchRedux || branchRedux == "") {
            let newFilterData = { ...filterData };
            newFilterData.branch_id = branchRedux;
            setFilterData({ ...newFilterData });
            fetch(paginationStorage ? paginationStorage : 1, "id:desc", { ...newFilterData });
        } else {
            fetch(paginationStorage ? paginationStorage : 1);
        }
        // setColumns(newColumns);

        return () => {
            if (!history.location.pathname.includes("/teacher-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Search placeholder="Tìm kiếm tên giáo viên" style={{ width: 250 }} onSearch={_handleSearchTable} />
                    <div>
                        {ExportExcelTeacher()}
                        <Button onClick={() => history.push("/teacher-detail")} type="primary">
                            Thêm mới
                        </Button>
                    </div>
                </div>
                <Row style={{ marginBottom: 0 }}>
                    <CustomTable
                        tableName={tableName}
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
};

export default Teacher;
