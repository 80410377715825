import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Select, Row, Col, notification } from "antd";

import { useHistory, useParams } from "react-router-dom";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "@components/CenterArea";
import { validateObject, toArray } from "@utils";
import { getAccount, createAccount, updateAccount, api_getListRole } from "./apiAccount";
import { api_getAllBranchOptions } from "@api/globalApi";
import { phoneValidation } from "@utils/validation";
import "./index.scss";

const { Option } = Select;

const Detail = () => {
    const [form] = NewForm.useForm();
    let history = useHistory();
    let { id } = useParams();

    const [listBranch, setListBranch] = useState([]);
    const [listRole, setListRole] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateObject(values);
                let arr = [];
                if (dataSubmit.roles == "All") {
                    dataSubmit.roles = arr;
                } else {
                    arr.push(parseFloat(dataSubmit.roles));
                    dataSubmit.roles = arr;
                }
                if (dataSubmit.branch_id == "All") {
                    dataSubmit.branch_id = null;
                }
                dataSubmit.roles = Array.from(dataSubmit.roles, Number);
                if (id) {
                    let res = await updateAccount(id, dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Cập nhật thành công",
                        });
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                } else {
                    let res = await createAccount(dataSubmit);
                    if (res.status) {
                        notification.success({
                            message: "Thông báo",
                            description: "Tạo mới thành công",
                        });
                        history.goBack();
                    } else {
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                    setLoading(false);
                }
            })
            .catch(() => {
                notification.error({
                    message: "Cảnh báo",
                    description: "Lỗi hệ thống",
                });
                setLoading(false);
            });
    };

    const initFetch = async () => {
        const res = await getAccount(id);
        if (res.status) {
            form.setFieldsValue({
                roles: res.data.roles.length > 0 ? res.data.roles[0].toString() : null,
                username: res.data.username,
                phone: res.data.phone,
                name: res.data.name,
                active: res.data.active,
                branch_id: res.data?.branch_id?.toString(),
            });
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        const _getAllBranch = async () => {
            let params = { params: { _orderBy: "id:asc" } };
            const res = await api_getAllBranchOptions(params);
            if (res.status) {
                if (res.data) {
                    setListBranch(toArray(res.data));
                }
            } else {
                console.log("err: ", res);
            }
        };
        const _getAllRole = async () => {
            const res = await api_getListRole();
            if (res.status) {
                if (res.data) {
                    setListRole(res.data);
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranch();
        _getAllRole();
        if (id) {
            setLoading(true);
            initFetch();
            setVisible(true);
        } else {
            form.setFieldsValue({
                active: true,
            });
            setVisible(true);
        }
        return () => {
            if (!history.location.pathname.includes("/account")) {
                sessionStorage.clear();
            }
        };
    }, []);

    return (
        <CenterArea xxl={1350} xl={940} xs="100%">
            <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                <FieldFloatItem label="Chi nhánh" name="branch_id">
                    <Select>
                        <Option key="20000" value="All">
                            Tất cả chi nhánh
                        </Option>
                        {listBranch &&
                            listBranch.map((item, i) => {
                                return (
                                    <Option key={i} value={item.id.toString()}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                    </Select>
                </FieldFloatItem>
                <FieldFloatItem
                    name="roles"
                    label="Phân quyền"
                    rules={[{ required: true, message: "Vui lòng chọn quyền" }]}
                >
                    <Select>
                        {listRole &&
                            listRole.map((item, i) => {
                                return (
                                    <Option key={i} value={item.id.toString()}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                    </Select>
                </FieldFloatItem>
                <FieldFloatItem label="Họ và tên" name="name">
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập số điện thoại",
                        },
                        {
                            pattern: phoneValidation,
                            message: "Vui lòng nhập đủ 10 chữ số",
                        },
                    ]}
                    label="Số điện thoại"
                    name="phone"
                >
                    <Input maxLength={10} />
                </FieldFloatItem>
                <FieldFloatItem
                    label="Tên đăng nhập"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng tên đăng nhập",
                        },
                    ]}
                >
                    <Input />
                </FieldFloatItem>
                <FieldFloatItem
                    label="Mật khẩu"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập mật khẩu",
                        },
                    ]}
                >
                    <Input type="password" />
                </FieldFloatItem>
                <Form.Item name="active">
                    <Radio.Group>
                        <Radio value={true}>Kích hoạt</Radio>
                        <Radio value={false}>Khoá</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item>
                    <Row type="flex" style={{ marginTop: 20 }}>
                        <Col style={{ marginRight: 10 }}>
                            <Button type="default" onClick={() => history.push("/account")}>
                                Quay lại
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" loading={loading} htmlType="submit">
                                {id ? "Cập nhật" : "Lưu"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </NewForm>
        </CenterArea>
    );
};

export default Detail;
