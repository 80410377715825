import React, { useState, useEffect, useRef } from "react";
import { DeleteOutlined, EditOutlined, PrinterOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table, Input, notification, Modal, Popconfirm } from "antd";
import { getListInvoices, removeInvoice } from "./apiInvoice";
import { useSelector } from "react-redux";
import { getHeightOfMainContent } from "@utils";
import useStateCallback from "@utils/CallbackHook";
import { Link } from "react-router-dom";
import { useMedia } from "react-media";
import ContextMenu from "@components/ContextMenu";
import { getFilterString } from "@utils";
import { getRole, getUserInfo } from "@utils/auth";
import { api_getAllBranch } from "@api/globalApi";
import { ExportExcelInvoice } from "@components/ExportExcel";
import { createColumnConfig } from "./ColumnsConfig";
import "./index.scss";

//fixColumns
import { getLocalColumns } from "@utils";
import CustomTable from "@components/Table/CustomTable";
import TableKey from "@utils/TableKey";
let tableName = TableKey.invoice;
let fixColumns = [
    { key: "stt", value: "Thứ tự" },
    { key: "code", value: "Mã phiếu" },
    { key: "type", value: "Loại" },
    { key: "name", value: "Họ tên" },
    { key: "branch_id", value: "Chi nhánh" },
    { key: "phone", value: "Số điện thoại" },
    { key: "content", value: "Nội dung" },
    { key: "status", value: "Trạng thái" },
    { key: "amount", value: "Số tiền" },
];
let Search = Input.Search;

function Invoice(props) {
    //fixColumns
    let localColumns = getLocalColumns(tableName, fixColumns);
    const [configColumns, setConfigColumns] = useState(localColumns);

    //screen
    const isLargeScreen = useMedia({ query: "(min-width: 768px)" });

    const { history } = props;
    const acl = useSelector((state) => state.acl.acl);
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const user_info = getUserInfo();

    //Table
    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        showSizeChanger: false,
    });
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [listBranch, setBranches] = useState([]);
    let paginationStorage = sessionStorage.getItem("pagination");

    const mounted = useRef(false);

    //filter
    const [filterData, setFilterData] = useStateCallback({});

    let newColumns = [
        {
            title: "STT",
            fixed: isLargeScreen ? "left" : "center",
            width: 40,
            align: "center",
            key: isLargeScreen ? "__" : "stt",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.pageSize + index + 1}</React.Fragment>
            ),
        },
        {
            title: "Mã phiếu",
            dataIndex: "code",
            align: "center",
            width: 100,
            key: "code",
        },
        {
            title: "Loại",
            dataIndex: "type",
            width: 60,
            render: (text, record) => <span>{record.type == 1 ? "Thu" : "Chi"}</span>,
            key: "type",
            align: "center",
        },
        {
            title: "Họ tên",
            dataIndex: "name",
            width: 150,
            key: "fullname",
            align: "center",
        },
        {
            title: "Chi nhánh",
            dataIndex: "branch_id",
            width: 150,
            key: "branch_id",
            render: (text, record) => {
                let branch = listBranch && listBranch.filter((item) => item.id == text);
                return <div>{branch?.[0]?.name}</div>;
            },
            align: "center",
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
            width: 100,
            key: "phone",
            align: "center",
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            width: 200,
            key: "content",
            align: "center",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: (text, record) => (
                <span>
                    {record.status
                        ? record.status == 1
                            ? "Chưa thanh toán"
                            : record.status == 2
                            ? "Đã thanh toán"
                            : record.status == 3
                            ? "Đã huỷ"
                            : ""
                        : ""}
                </span>
            ),
            filters: [
                { text: "Chưa thanh toán", value: 1 },
                { text: "Đã thanh toán", value: 2 },
                { text: "Đã huỷ", value: 3 },
            ],
            filterMultiple: false,
            width: 120,
            key: "status",
            align: "center",
        },
        {
            title: "Số tiền",
            dataIndex: "amount",
            align: "center",
            // className: "column-money",
            render: (text, record) => <span>{`${numberWithCommas(record.amount)} VNĐ`}</span>,
            width: 120,
            key: "paid",
        },
    ];

    let actionColumns = [
        ...newColumns,
        {
            title: "Hành động",
            dataIndex: "",
            width: 130,
            align: "center",
            key: "action",
            render: (text, record, index) => (
                <div style={{ display: "inline-block" }}>
                    <Link
                        className="pne-update-controll"
                        to={{
                            pathname: "/invoice-detail/" + record.id,
                            state: { invoiceData: record },
                        }}
                    >
                        <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                    </Link>
                    <Popconfirm
                        title="Chắc chắn huỷ?"
                        onConfirm={() => onDeleteRow(record, index)}
                        okText="Đồng ý"
                        cancelText="Không"
                    >
                        <a title="Huỷ bỏ">
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                        </a>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    // const onSelectChange = (selectedRowKeys) => {
    //     setSelectedRowKeys(selectedRowKeys);
    // };
    const resetConfigColumns = () => {
        setConfigColumns(fixColumns);
        localStorage.setItem(TableKey.invoice, JSON.stringify(fixColumns));
    };

    const handleTableChange = (paginations, filters) => {
        let newSorter = "id:desc";
        let newFilterData = { ...filterData };
        if (Object.keys(filters).length !== 0) {
            if (filters.status && filters.status.length > 0) {
                newFilterData.status = filters.status[0].toString();
            } else {
                delete newFilterData.status;
            }
        }
        setPagination({ ...pagination, current: paginationStorage || paginations.current });
        setFilterData(newFilterData);
        fetch(paginations.current, newSorter, newFilterData);
    };

    const handleSearch = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        fetch(1, "id:desc", { ...newFilterData });
    };

    const createNewInvoice = () => {
        props.history.push("/create-invoice");
    };

    const onUpdateRow = (record) => {
        sessionStorage.setItem("pagination", pagination.current);
        props.history.push({
            pathname: "/invoice-detail/" + record.id,
        });
    };

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const onDeleteRow = (record) => {
        Modal.confirm({
            title: "Chắc chẳn huỷ?",
            async onOk() {
                let { status } = await removeInvoice(record.id);
                if (status) {
                    fetch();
                    notification.success({
                        message: "Thông báo",
                        description: "Huỷ thành công",
                    });
                } else {
                    notification.error({
                        message: "Thông báo",
                        description: res.message,
                    });
                }
            },
        });
    };

    const checkRoleBranch = (record, type) => {
        if (user_info.branch_id) {
            //is_staff
            if (acl.hasAllowed(getRole(), "invoice", type) && user_info.branch_id === record.branch_id) {
                return true;
            }
            return false;
        } else {
            //is_admin
            return true;
        }
    };

    const fetch = async (page = 1, sort = "id:desc", filterData = {}) => {
        setLoading(true);
        let filter = getFilterString(filterData);
        let params = {
            params: {
                _relations: "creator,confirm_by,course,branch,deletor",
                _page: page,
                _orderBy: sort,
                _filter: filter,
            },
        };
        try {
            let res = await getListInvoices(params);
            if (res.status) {
                setData(res.data);
                setPagination({
                    ...pagination,
                    total: res.meta.pagination.total,
                    pageSize: res.meta.pagination.per_page,
                    current: res.meta.pagination.current_page,
                });
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch {
            setLoading(false);
        }
    };

    const _handleChangeGlobalBranch = () => {
        let newSorter = "id:desc";
        let newFilterData = { ...filterData, branch_id: branchRedux || "" };
        setFilterData(newFilterData);
        fetch(paginationStorage ? paginationStorage : pagination.current, newSorter, newFilterData);
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        const _getAllBranch = async () => {
            let res = await api_getAllBranch();
            if (res.status) {
                if (res.data) {
                    setBranches(res.data);
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();

        return () => {
            if (!history.location.pathname.includes("/invoice-detail")) {
                sessionStorage.clear();
            }
        };
    }, []);

    useEffect(() => {
        _handleChangeGlobalBranch();
    }, [branchRedux]);

    useEffect(() => {
        const columnConfig = createColumnConfig(configColumns, {
            pagination,
            listBranch,
            fixColumns,
            setConfigColumns,
            history,
            tableName,
        });
        setColumns(columnConfig);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => props.history.push("/print/" + record.id),
                    render: () => (
                        <>
                            <PrinterOutlined style={{ fontSize: 16, padding: 5 }} />
                            In
                        </>
                    ),
                },
                {
                    showItem: (record) => checkRoleBranch(record, "update"),
                    onClick: (record) => onUpdateRow(record),
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Cập nhật
                        </>
                    ),
                },
                {
                    showItem: (record) => checkRoleBranch(record, "cancel"),
                    onClick: (record) => onDeleteRow(record),
                    render: () => (
                        <>
                            {/* <a title="Huỷ phiếu"> */}
                            <DeleteOutlined style={{ fontSize: 16, padding: 5 }} />
                            Huỷ bỏ
                            {/* </a> */}
                        </>
                    ),
                },
            ],
        });
    }, [pagination, branchRedux, configColumns, listBranch]);

    //set pagination
    useEffect(() => {
        if (paginationStorage) {
            let newPagination = { ...pagination };
            newPagination.current = paginationStorage ? paginationStorage : pagination.current;
            setPagination(newPagination);
        }
    }, [paginationStorage]);

    useEffect(() => {
        mounted.current = true;
        if (listBranch?.length !== 0) {
            fetch(pagination.current, _, filterData);
        } else {
            setLoading(false);
        }
        return () => (mounted.current = false);
    }, [pagination.current, filterData, listBranch]);

    // let rowSelection = false;
    // if (acl.hasAllowed(getRole(), "invoice", "confirm")) {
    //     rowSelection = {
    //         selectedRowKeys,
    //         onChange: onSelectChange,
    //     };
    // }

    // let hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <Row type="flex" justify="space-between">
                <Col>
                    <Search
                        placeholder="Tìm kiếm theo họ tên hoặc SĐT"
                        style={{ width: 300, marginBottom: 10 }}
                        onSearch={handleSearch}
                    />
                </Col>
                <Col>
                    {ExportExcelInvoice()}
                    <Button type="primary" onClick={createNewInvoice}>
                        Thêm hoá đơn
                    </Button>
                </Col>
            </Row>
            <Row type="flex" justify="center">
                <Col span={24}>
                    <Row style={{ marginBottom: 0 }}>
                        <CustomTable
                            tableName={tableName}
                            bordered
                            onRow={onRowTable}
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={handleTableChange}
                            scroll={{
                                y: getHeightOfMainContent(),
                            }}
                        />
                        <ContextMenu {...menuContext} />
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Invoice;
