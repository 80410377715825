import React, { useState, useEffect } from "react";
import { Modal, Input, notification, Select, Row, Col, DatePicker, Radio, Form } from "antd";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { api_createPromotion, api_updatePromotion } from "./apiPromotion";
import { numberWithCommas, numberWithoutCommas } from "@utils";
import { numberValidation, currencyValidation, percentageValidation } from "@utils/validation";
import PropTypes from "prop-types";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

function ModalComponent(props) {
    //props
    const { fetch, visible, isUpdate, setShowModal, dataModal, showModal, pagination } = props;

    //define form instance
    const [form] = NewForm.useForm();

    //state
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isPercentage, setIsPercentage] = useState(true);

    useEffect(() => {
        if (showModal && isUpdate) {
            setIsPercentage(dataModal.check_value);
            form.setFieldsValue({
                name: dataModal.name || "",
                description: dataModal.description || "",
                value: dataModal.value ? numberWithCommas(dataModal.value) : "",
                check_value: dataModal.check_value ? "1" : "0",
                count_use: dataModal.count_use,
                count_user_used: dataModal.count_user_used,
                begin_date: dataModal.begin_date ? moment(dataModal.begin_date, "YYYY-MM-DD") : null,
                end_date: dataModal.end_date ? moment(dataModal.end_date, "YYYY-MM-DD") : null,
                active: !!dataModal.active,
            });
        } else {
            setIsPercentage(true);
            form.setFieldsValue({
                name: "",
                description: "",
                value: "",
                check_value: "1",
                count_use: "",
                count_user_used: "",
                begin_date: null,
                end_date: null,
                active: true,
            });
        }
    }, [showModal, isUpdate, dataModal]);

    const handleCancel = () => {
        form.resetFields();
        setShowModal(false);
    };

    const onSubmitRow = () => {
        setConfirmLoading(true);
        form.validateFields()
            .then(async (values) => {
                const dataSubmit = { ...values };
                dataSubmit.count_use = +dataSubmit.count_use;
                dataSubmit.count_user_used = +dataSubmit.count_user_used;
                dataSubmit.value = +numberWithoutCommas(dataSubmit.value);
                dataSubmit.begin_date = dataSubmit.begin_date.format("YYYY-MM-DD");
                dataSubmit.end_date = dataSubmit.end_date.format("YYYY-MM-DD");
                dataSubmit.check_value = dataSubmit.check_value === "1" ? true : false;

                if (isUpdate) {
                    const res = await api_updatePromotion(dataModal.id, dataSubmit);
                    if (res.status) {
                        fetch(pagination.current);
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Cập nhật thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                } else {
                    const res = await api_createPromotion(dataSubmit);
                    if (res.status) {
                        fetch();
                        setConfirmLoading(false);
                        setShowModal(false);
                        form.resetFields();
                        notification.success({
                            message: "Thông báo",
                            description: res.message || "Tạo mới thành công",
                        });
                    } else {
                        setConfirmLoading(false);
                        notification.error({
                            message: "Cảnh báo",
                            description: res.message || "Lỗi hệ thống",
                        });
                    }
                }
            })
            .catch((err) => {
                setConfirmLoading(false);
                console.log(err);
                notification.error({
                    message: "Cảnh báo",
                    description: "Không thể gửi yêu cầu, vui lòng thử lại",
                });
            });
    };

    const handleChangeValue = (e) => {
        form.setFieldsValue({ value: e.target.value ? numberWithCommas(e.target.value) : "" });
    };

    return (
        <div>
            <Modal
                centered
                title={isUpdate ? "Cập nhật khuyến mại" : "Thêm khuyến mại"}
                cancelText="Hủy"
                okText={isUpdate ? "Cập nhật" : "Lưu"}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                onOk={onSubmitRow}
            >
                <NewForm className="new-form modal-form" form={form}>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem
                                label="Chương trình khuyến mãi"
                                name="name"
                                rules={[{ required: true, message: "Vui lòng nhập tên khuyến mại!" }]}
                            >
                                <Input />
                            </FieldFloatItem>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <FieldFloatItem label="Mô tả" name="description">
                                <TextArea />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={16}>
                            <FieldFloatItem
                                label="Giá trị"
                                name="value"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập giá trị",
                                    },
                                    isPercentage
                                        ? {
                                              pattern: percentageValidation,
                                              message: "Vui lòng chỉ nhập số từ 0 đến 100",
                                          }
                                        : {
                                              pattern: currencyValidation,
                                              message: "Vui lòng không nhập chữ cái",
                                          },
                                ]}
                            >
                                <Input onChange={handleChangeValue} />
                            </FieldFloatItem>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <FieldFloatItem label="Đơn vị tính" name="check_value">
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => setIsPercentage(value === "1")}
                                >
                                    <Option value={"0"}>VNĐ</Option>
                                    <Option value={"1"}>%</Option>
                                </Select>
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Số lượt sử dụng tối đa"
                                name="count_use"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập số lượt",
                                    },
                                    {
                                        pattern: numberValidation,
                                        message: "Vui lòng chỉ nhập số",
                                    },
                                ]}
                            >
                                <Input />
                            </FieldFloatItem>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem label="Số lượt sử dụng hiện tại" name="count_user_used">
                                <Input disabled />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Thời gian bắt đầu"
                                name="begin_date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập thời gian bắt đầu",
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder={null} />
                            </FieldFloatItem>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <FieldFloatItem
                                label="Thời gian kết thúc"
                                name="end_date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập thời gian kết thúc",
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder={null} />
                            </FieldFloatItem>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                        <Col span={24}>
                            <Form.Item label="Trạng thái" name="active">
                                <Radio.Group>
                                    <Radio value={true}>Đang sử dụng</Radio>
                                    <Radio value={false}>Hết hạn</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </NewForm>
            </Modal>
        </div>
    );
}

ModalComponent.propTypes = {
    visible: PropTypes.bool,
    confirmLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    setShowModal: PropTypes.func.isRequired,
    dataModal: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
};

export default ModalComponent;
