import React, { useState, useEffect } from "react";
import { Checkbox, Button } from "antd";

const ConfigColumnsForTable = (props) => {
    const { fixColumns, configColumns, setConfigColumns, tableName } = props;
    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const [columns, setColumns] = useState(configColumns);
    const [saveLocal, setSaveLocal] = useState(localColumns);

    useEffect(() => {
        if (configColumns.length > 0) {
            setColumns(configColumns);
            // console.log("9999999999", localColumns, configColumns);
            if (localColumns) {
                setSaveLocal(localColumns);
            } else {
                let arr = [];
                fixColumns.map((item) => {
                    if (item.key.toLowerCase() == "stt") {
                        arr.push({ key: item.key, width: 70, status: true });
                    } else {
                        arr.push({ key: item.key, width: 110, status: true });
                    }
                });
                setSaveLocal(arr);
            }
        }
    }, [configColumns]);

    const checkBoxConfigColumns = () => {
        return fixColumns.map((item, index) => (
            <div key={index} style={{ marginTop: 5 }}>
                <Checkbox
                    name={item.key}
                    onChange={(e) => changeDisplayColumns(e, item.key)}
                    checked={columns && columns.includes(item.key)}
                    key={index.key}
                    disabled={item.key.toLowerCase() == "stt"}
                >
                    {item.value}
                </Checkbox>
            </div>
        ));
    };
    const changeDisplayColumns = (e, item) => {
        if (e.target.checked === false) {
            let newConfigColumns = [...columns];
            let pos = newConfigColumns.indexOf(item);
            if (pos >= 0) {
                newConfigColumns.splice(pos, 1);
                setColumns(newConfigColumns);
            }
            let newSaveLocal = [...saveLocal];
            newSaveLocal.map((i) => {
                if (i.key == item) {
                    i.status = false;
                }
            });
            setSaveLocal(newSaveLocal);
        } else {
            let newConfigColumns = [...columns];
            newConfigColumns.push(item);
            setColumns(newConfigColumns);

            let newSaveLocal = [...saveLocal];
            let check = false;
            newSaveLocal.map((i) => {
                if (i.key == item) {
                    i.status = true;
                    check = true;
                }
            });
            if (!check) {
                newSaveLocal.splice(1, 0, { key: item, width: 110, status: true });
            }
            setSaveLocal(newSaveLocal);
        }
    };
    const confirmChange = () => {
        setConfigColumns(columns);
        localStorage.setItem(tableName, JSON.stringify(saveLocal));
    };
    return (
        <>
            {/* {console.log("88888888888888", columns, saveLocal)} */}
            <div style={{ height: 250, overflow: "auto" }}>{checkBoxConfigColumns()}</div>
            <Button
                disabled={columns === configColumns}
                style={{ marginTop: "10px", width: "100%" }}
                type="primary"
                onClick={confirmChange}
            >
                Lưu
            </Button>
        </>
    );
};

export default React.memo(ConfigColumnsForTable);
