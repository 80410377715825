import React, { useState, useEffect } from "react";
import { Row, Col, Table, notification, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

import ContextMenu from "@components/ContextMenu";
import { createColumnConfig } from "./ColumnsCourseConfig";
import { getHeightOfMainContent } from "@utils";
import { api_getAllBranch, api_getAllTypeOfClass } from "@api/globalApi";

let { Search } = Input;

const Course = (props) => {
    // const acl = useSelector((state) => state.acl.acl);

    const { history, listCourse, listTypeOfClass } = props;

    const [menuContext, setMenuContext] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const [filterData, setFilterData] = useState({});
    const [listTeacher, setListTeacher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listBranch, setBranch] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pagination, setPagination] = useState({ showSizeChanger: false, current: 1 });

    const onRowTable = (record) => ({
        onContextMenu: (event) => {
            event.preventDefault();
            if (!menuContext.visible) {
                document.addEventListener(`click`, function onClickOutside() {
                    setMenuContext({ ...menuContext, visible: false });
                    document.removeEventListener(`click`, onClickOutside);
                });
            }
            setMenuContext({
                ...menuContext,
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(filters);
        let newSorter = "id:desc";
        let newFilters = {};
        if (Object.keys(sorter).length !== 0) {
            // fetch(pagination.current, sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc"));
            newSorter = sorter.field + (sorter.order === "ascend" ? ":asc" : ":desc");
        }
        if (filters.branch_id) {
            newFilters = { branch_id: filters.branch_id[0] };
        }

        // fetch(pagination.current, sorter, newFilters);
    };

    const _handleSearchTable = (value) => {
        let newFilterData = { ...filterData };
        if (value) {
            newFilterData.slug = value;
        } else {
            delete newFilterData.slug;
        }
        setFilterData({ ...newFilterData });
        // fetch(1, "id:desc", { ...newFilterData });
    };

    useEffect(() => {
        const columnConfig = createColumnConfig({
            pagination,
            listTeacher,
            listBranch,
            listTypeOfClass,
        });
        setColumns(columnConfig);
    }, [listBranch, pagination]);

    useEffect(() => {
        const _getAllBranch = async () => {
            const res = await api_getAllBranch();
            if (res.status) {
                if (res.data) {
                    setBranch(res.data);
                }
            } else {
                console.log("hello: ", res);
            }
        };
        _getAllBranch();
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        // setColumns(newColumns);
        setMenuContext({
            ...menuContext,
            menu: [
                {
                    showItem: () => true,
                    onClick: (record) => {
                        // history.push(`/course-detail/${record.id}`);
                        window.open(`/course-detail/${record.id}`, "_blank");
                    },
                    render: () => (
                        <>
                            <EditOutlined style={{ fontSize: 16, padding: 5 }} />
                            Xem chi tiết
                        </>
                    ),
                },
            ],
        });
    }, []);

    return (
        <Row type="flex" justify="center">
            <Col span={24}>
                <Row style={{ marginBottom: 0 }}>
                    <Table
                        bordered
                        onRow={onRowTable}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={listCourse}
                        pagination={false}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={{
                            y: getHeightOfMainContent(),
                            x: 1800,
                        }}
                    />
                    <ContextMenu {...menuContext} />
                </Row>
            </Col>
        </Row>
    );
};

export default Course;
