import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Row, Col, notification, Tabs, Select, DatePicker, Upload, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import { validateObject } from "@utils";
import CenterArea from "@components/CenterArea";
import { createStudent, updateStudent, getStudent } from "./apiStudent";
import { api_uploadImage, api_deleteImage, api_getAllProvince, api_getDistrictByProvince } from "@api/globalApi";
import POSITIONS from "@constants/positions";
import { phoneValidation, emailValidation } from "@utils/validation";
import "./index.scss";

let RadioGroup = Radio.Group;
let { TextArea } = Input;
let { TabPane } = Tabs;
let { Option } = Select;
let FormItem = Form.Item;

const Detail = () => {
    let history = useHistory();
    let { student_id } = useParams();
    const [form] = NewForm.useForm();

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [image, setImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [currentProvinceId, setCurrentProvinceId] = useState("");
    const [districts, setDistricts] = useState([]);

    const handleBack = () => {
        history.push("/student");
    };

    const _handleChangeImage = (info) => {
        if (info.file.status === "uploading") {
            let { file } = info;
            const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/gif" ||
                file.type === "image/x-icon";
            if (!isJpgOrPng) {
                message.error("Bạn chỉ upload ảnh định dạng JPG/PNG/GIF/ICO !");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error("Ảnh phải có kích thước nhỏ hơn 2MB!");
                return;
            }
        }
        //success
        let { fileList } = info;
        if (fileList.length == 0) {
            if (typeof image === "string") {
                setOldImage(image);
            }
            setImage(null);
        } else {
            setImage(fileList[0]);
        }
    };

    const onSubmit = async () => {
        setLoading(true);
        const sendData = (imageurl) => {
            form.validateFields()
                .then(async (values) => {
                    let dataSubmit = validateObject(values);
                    dataSubmit.birthday = dataSubmit.birthday ? dataSubmit.birthday.format("YYYY-MM-DD") : null;
                    dataSubmit.image = imageurl ? imageurl : null;
                    if (student_id) {
                        let res = await updateStudent(student_id, dataSubmit);
                        if (res.status) {
                            notification.success({
                                message: "Thông báo",
                                description: "Cập nhật thành công",
                            });
                        } else {
                            notification.error({
                                message: "Cảnh báo",
                                description: res.message || "Lỗi, Vui lòng thử lại!",
                            });
                        }
                        setLoading(false);
                    } else {
                        let res = await createStudent(dataSubmit);
                        if (res.status) {
                            notification.success({
                                message: "Thông báo",
                                description: "Tạo mới thành công",
                            });
                            history.goBack();
                        } else {
                            notification.error({
                                message: "Cảnh báo",
                                description: res.message || "Lỗi, Vui lòng thử lại!",
                            });
                        }
                        setLoading(false);
                    }
                })
                .catch(() => {
                    notification.error({
                        message: "Cảnh báo",
                        description: "Lỗi hệ thống",
                    });
                    setLoading(false);
                });
        };
        if (image) {
            if (typeof image !== "string") {
                const resImage = await api_uploadImage(image);
                if (resImage.status) {
                    sendData(resImage.result.file);
                }
            } else sendData(image);
        } else {
            if (oldImage) {
                api_deleteImage(oldImage);
            }
            sendData();
        }
    };

    const uploadButton = (
        <div>
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const initFetch = async () => {
        setLoading(true);
        const res = await getStudent(student_id);
        if (res.status) {
            setImage(res.data.image);
            setCurrentProvinceId(res.data.province_id);
            form.setFieldsValue({
                name: res.data.name,
                phone: res.data.phone,
                email: res.data.email,
                gender: res.data.gender,
                birthday: res.data.birthday ? moment(res.data.birthday, "YYYY-MM-DD") : null,
                address: res.data.address,
                district_id: res.data.district_id?.toString(),
                province_id: res.data.province_id?.toString(),
                country: res.data.country,
                side_foot: res.data.side_foot,
                position: res.data.position,
                skill: res.data.skill,
                achievement: res.data.achievement,
                note: res.data.note,
            });
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    const _getAllProvince = async () => {
        try {
            const res = await api_getAllProvince();
            if (res.status) {
                let newProvinces = [];
                for (const [key, value] of Object.entries(res.data)) {
                    let newItem = {};
                    newItem.value = key;
                    newItem.text = value;
                    newProvinces.push(newItem);
                }
                setProvinces(newProvinces);
            } else {
                setProvinces([]);
            }
        } catch (err) {
            setProvinces([]);
        }
    };

    useEffect(() => {
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        _getAllProvince();
        form.setFieldsValue({
            country: "Việt Nam",
            side_foot: "2",
        });
        if (student_id) {
            initFetch();
            setVisible(true);
        } else {
            setVisible(true);
        }
    }, []);

    useEffect(() => {
        const fetchAvailableDistricts = async () => {
            if (currentProvinceId) {
                try {
                    const res = await api_getDistrictByProvince(currentProvinceId);
                    if (res.status) {
                        let newDistricts = [];
                        for (const [key, value] of Object.entries(res.data)) {
                            let newItem = {};
                            newItem.value = key;
                            newItem.text = value;
                            newDistricts.push(newItem);
                        }
                        setDistricts(newDistricts);
                    } else {
                        setDistricts([]);
                    }
                } catch (err) {
                    setDistricts([]);
                }
            }
        };
        fetchAvailableDistricts();
    }, [currentProvinceId]);

    const handleChangeRating = (value, id) => {
        let newRating = [...rating];
        for (let i of newRating) {
            if (i.id === id) {
                i.value = value + "" || "0";
            }
        }
        setRating(newRating);
        form.setFieldsValue({
            skill: newRating,
        });
    };

    const handleChangeProvinceID = (value) => {
        setCurrentProvinceId(value);
        form.setFieldsValue({
            district_id: "",
        });
    };

    return (
        <Tabs
            defaultActiveKey="1"
            onTabClick={(data) => {
                if (data == 2) {
                    history.push("/student-course/" + student_id);
                }
                if (data == 3) {
                    history.push("/student-card/" + student_id);
                }
            }}
        >
            <TabPane tab="Thông tin học viên" key="1">
                <CenterArea xxl={1350} xl={940} xs="100%">
                    <NewForm className="new-form" form={form} onFinish={onSubmit} visible={visible}>
                        <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} md={6}>
                                <Upload
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("Ok");
                                        }, 0);
                                    }}
                                    listType="picture-card"
                                    fileList={
                                        image
                                            ? image.uid
                                                ? [image]
                                                : [
                                                      {
                                                          uid: "1",
                                                          name: "image.png",
                                                          status: "done",
                                                          url: image,
                                                      },
                                                  ]
                                            : []
                                    }
                                    // onPreview={this.handlePreview}
                                    onChange={_handleChangeImage}
                                >
                                    {!image && uploadButton}
                                </Upload>
                            </Col>
                            <Col xs={24} md={18}>
                                <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem
                                            label="Họ tên học viên"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập họ tên học viên",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập số điện thoại",
                                                },
                                                {
                                                    pattern: phoneValidation,
                                                    message: "Vui lòng nhập đủ 10 chữ số",
                                                },
                                            ]}
                                            label="Số điện thoại"
                                            name="phone"
                                        >
                                            <Input maxLength={10} />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <FieldFloatItem label="Ngày sinh" name="birthday">
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                placeholder={null}
                                                format="DD/MM/YYYY"
                                            />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <FormItem label="Giới tính" name="gender">
                                            <RadioGroup name="gender">
                                                <Radio value={0}>Nữ</Radio>
                                                <Radio value={1}>Nam</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    pattern: emailValidation,
                                                    message: "Vui lòng nhập chính xác địa chỉ email",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={8}>
                                        <FieldFloatItem label="Tỉnh/TP" name="province_id">
                                            <Select onChange={handleChangeProvinceID}>
                                                {provinces.map((item) => (
                                                    <Option key={item.value} value={item.value.toString()}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={8}>
                                        <FieldFloatItem label="Quận/Huyện" name="district_id">
                                            <Select>
                                                {districts.map((item) => (
                                                    <Option key={item.value} value={item.value.toString()}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={8}>
                                        <FieldFloatItem label="Số nhà, tên đường" name="address">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col>

                                    {/* <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Quốc gia" name="country">
                                            <Input />
                                        </FieldFloatItem>
                                    </Col> */}

                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Ghi chú" name="note">
                                            <TextArea name="note" rows={2} />
                                        </FieldFloatItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={20}>
                                        <Form.Item>
                                            <Row type="flex">
                                                <Col style={{ marginRight: 10 }}>
                                                    <Button type="default" onClick={handleBack}>
                                                        Quay lại
                                                    </Button>
                                                </Col>
                                                <Col>
                                                    <Button type="primary" loading={loading} htmlType="submit">
                                                        {student_id ? "Lưu" : "Tạo mới"}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </NewForm>
                </CenterArea>
            </TabPane>
            {student_id && <TabPane tab="Quản lý khóa học" key="2" />}
            {/* {student_id && <TabPane tab="Quản lý thẻ" key="3" />} */}
        </Tabs>
    );
};

export default Detail;
