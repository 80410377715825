import React, { useEffect, useState } from "react";
import { Button } from "antd";
import moment from "moment";
import ReactExport from "react-data-export";
import {
    api_getAllBranch,
    api_getAllBranchOptions,
    api_getAllTeacher,
    api_getAllStudent,
    api_getAllCourses,
    api_getAllInvoices,
    api_getAllTypeOfClass,
    api_getAllCards,
    api_getAllPromotions,
} from "@api/globalApi";
import { toArray } from "@utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportExcelStudent = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllStudent({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Học viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn
                    label="Khóa học"
                    value={(col) => {
                        let data = [];
                        if (col.courses_name.length > 0) {
                            col.courses_name.map((item) => {
                                data.push(item.name);
                            });
                            return data.join(", ");
                        }
                        return "";
                    }}
                />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender == 1 ? "Nam" : "Nữ")} />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Ngày sinh" value="birthday" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn label="Hình ảnh" value="image" />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_student"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelTeacher = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Giáo viên">
                <ExcelColumn label="Họ tên" value="name" />
                <ExcelColumn label="Số điện thoại" value="phone" />
                <ExcelColumn label="Giới tính" value={(col) => (col.gender == 1 ? "Nam" : "Nữ")} />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Năm sinh" value="born_year" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn label="Hình ảnh" value="image" />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_teacher"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelTypeOfClass = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllTypeOfClass({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Loại lớp">
                <ExcelColumn label="Loại lớp" value="name" />
                <ExcelColumn
                    label="Ngày tạo"
                    value={(col) => (col.created_at ? moment(col.created_at, "YYYY-MM-DD").format("DD/MM/YYYY") : "")}
                />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_type_of_class"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelCard = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllCards({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Thẻ">
                <ExcelColumn label="Tên thẻ" value="type_card" />
                <ExcelColumn label="Giá trị" value="value" />
                <ExcelColumn label="Số buổi" value={(col) => col.number_lesson || "Không giới hạn"} />
                {/* <ExcelColumn
                    label="Các khóa học được áp dụng"
                    value={(col) => {
                        return col.course_names.map((item) => item);
                    }}
                /> */}
                <ExcelColumn label="Trạng thái" value={(col) => (col.active ? "Đang mở" : "Khóa")} />
                <ExcelColumn label="Ngày tạo" value="created_at" />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_type_of_class"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelPromotion = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllPromotions({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Khuyến mại">
                <ExcelColumn label="Chương trình khuyến mại" value="name" />
                <ExcelColumn label="Mô tả" value="description" />
                <ExcelColumn label="Giá trị" value="value" />
                <ExcelColumn label="Đơn vị tính" value={(col) => (col.check_value ? "%" : "VNĐ")} />
                <ExcelColumn label="Số lượt sử dụng tối đa" value="count_use" />
                <ExcelColumn label="Số lượt sử dụng hiện tại" value="count_user_used" />
                <ExcelColumn label="Ngày áp dụng" value="begin_date" />
                <ExcelColumn label="Ngày kết thúc" value="end_date" />
                <ExcelColumn label="Trạng thái" value={(col) => (col.active ? "Đang mở" : "Khóa")} />
                <ExcelColumn label="Ngày tạo" value="created_at" />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_type_of_class"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelBranch = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllBranch({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Chi nhánh">
                <ExcelColumn label="Tên chi nhánh" value="name" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Số sân thuê" value="number_yard" />
                <ExcelColumn label="Trạng thái" value={(col) => (col.active == 1 ? "Đang mở" : "Đã đóng")} />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_branch"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelInvoice = () => {
    const [listBranch, setBranch] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const _getAllBranchOptions = async () => {
            const res = await api_getAllBranchOptions({
                _orderBy: "id:asc",
            });
            if (res.status) {
                if (res.data) {
                    let arr = toArray(res.data);
                    setBranch(arr);
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranchOptions();
        _getList();
    }, []);

    const _getList = async () => {
        const res = await api_getAllInvoices({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Hóa đơn">
                <ExcelColumn label="Mã hoá đơn" value="code" />
                <ExcelColumn label="Họ tên học viên" value="name" />
                <ExcelColumn label="Số tiền" value="amount" />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Loại hoá đơn"
                    value={(col) => {
                        if (col.type == 1) {
                            return "Thu";
                        }
                        if (col.type == 0) {
                            return "Chi";
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Số điện thoại học viên" value="phone" />
                <ExcelColumn label="Địa chỉ" value="address" />
                <ExcelColumn label="Nội dung" value="content" />
                <ExcelColumn label="Ghi chú" value="note" />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_invoice"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};

export const ExportExcelCourse = () => {
    const [listBranch, setBranch] = useState([]);
    const [dataTeacher, setDataTeacher] = useState([]);
    const [data, setData] = useState([]);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);

    useEffect(() => {
        const _getAllBranchOptions = async () => {
            const res = await api_getAllBranchOptions({
                _orderBy: "id:asc",
            });
            if (res.status) {
                if (res.data) {
                    let arr = toArray(res.data);
                    setBranch(arr);
                }
            } else {
                console.log("err: ", res);
            }
        };
        _getAllBranchOptions();
        _getList();
        _getListTeacher();
        _getListAllTypeOfClass();
    }, []);

    const _getListTeacher = async () => {
        const res = await api_getAllTeacher({ paging: 0 });
        if (res.status) {
            setDataTeacher(res.data);
        }
    };

    const _getList = async () => {
        const res = await api_getAllCourses({ paging: 0 });
        if (res.status) {
            setData(res.data);
        }
    };

    const _getListAllTypeOfClass = async () => {
        const res = await api_getAllTypeOfClass({ paging: 0 });
        if (res.status) {
            setListTypeOfClass(res.data);
        }
    };

    const _renderExportList = () => {
        return (
            <ExcelSheet data={data} name="Khoá học">
                <ExcelColumn label="Tên khoá học" value="name" />
                <ExcelColumn label="Học phí" value="tuition" />
                <ExcelColumn
                    label="Loại lớp"
                    value={(col) => {
                        let filtedValue = listTypeOfClass.find((item) => item.id === col.type_id);
                        return filtedValue?.name || "Không xác định";
                    }}
                />
                <ExcelColumn label="Số buổi học" value={(col) => (col.duration ? col.duration : "Linh động")} />
                <ExcelColumn
                    label="Giáo viên"
                    value={(col) => {
                        let teacher = dataTeacher.filter((item, index) => {
                            return item.id == col.teacher_id;
                        });
                        if (teacher[0]) {
                            return teacher[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn
                    label="Chi nhánh"
                    value={(col) => {
                        let branch = listBranch.filter((item) => {
                            return item.id == col.branch_id;
                        });
                        if (branch[0]) {
                            return branch[0].name;
                        }
                        return "Không rõ";
                    }}
                />
                <ExcelColumn label="Ghi chú" value="note" />
                <ExcelColumn
                    label="Thứ 2"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_1.start_time && col.day_1.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_1.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_1.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 3"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_2.start_time && col.day_2.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_2.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_2.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 4"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_3.start_time && col.day_3.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_3.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_3.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 5"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_4.start_time && col.day_4.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_4.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_4.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 6"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_5.start_time && col.day_5.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_5.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_5.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Thứ 7"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_6.start_time && col.day_6.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_6.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_6.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
                <ExcelColumn
                    label="Chủ nhật"
                    value={(col) => {
                        let infor = "Không có lịch";
                        if (col.day_7.start_time && col.day_7.end_time) {
                            infor = `Bắt đầu: ${moment(col.day_7.start_time, "HH:mm").format(
                                "HH:mm"
                            )} - Kết thúc: ${moment(col.day_7.end_time, "HH:mm").format("HH:mm")}`;
                        }
                        return infor;
                    }}
                />
            </ExcelSheet>
        );
    };

    return (
        <div style={{ display: "inline-block" }}>
            <ExcelFile
                filename="export_course"
                fileExtension="xlsx"
                element={<Button style={{ marginRight: 10 }}>Xuất file</Button>}
            >
                {_renderExportList()}
            </ExcelFile>
        </div>
    );
};
