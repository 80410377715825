import React, { useState, useEffect, useRef } from "react";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, notification, Row, Col } from "antd";
import { getUserInfo, updateUserInfo } from "./apiAccount";

function ProfileComponent() {
    const [form] = NewForm.useForm();
    const [confirmDirty, setConfirmDirty] = useState(false);
    const mounted = useRef(false);

    const fetchUserInfo = async () => {
        let res = await getUserInfo();
        if (res.status) {
            form.setFieldsValue({
                username: res.data.username,
            });
        }
    };

    const updateUser = async (data) => {
        let { status } = await updateUserInfo(data);
        if (status) {
            notification.success({
                message: "Thông báo",
                description: "Đổi mật khẩu thành công",
            });
        } else {
            notification.error({
                message: "Cảnh báo",
                description: "Mật khẩu hiện tại không đúng",
            });
        }
    };

    useEffect(() => {
        mounted.current = true;
        notification.config({
            placement: "bottomRight",
            bottom: 20,
            duration: 5,
        });
        fetchUserInfo();
        return () => (mounted.current = false);
    }, []);

    const handleSubmitAccount = () => {
        form.validateFields()
            .then((values) => {
                let dataSubmit = {
                    old_password: values.old_password,
                    password: values.new_password,
                };
                updateUser(dataSubmit);
            })
            .catch((err) => {
                throw new Error(err);
            });
    };

    const handleConfirmBlur = (e) => {
        let value = e.target.value;
        setConfirmDirty(confirmDirty || !!value);
    };

    const checkPassword = async (rule, value) => {
        if (value && value === form.getFieldValue("old_password")) {
            throw new Error("Mật khẩu mới phải khác mật khẩu cũ");
        }
    };

    const checkConfirm = async (rule, value) => {
        // if (value && confirmDirty) {
        //     form.validateFields(["confirm"], { force: true });
        // }
        if (value && value !== form.getFieldValue("new_password")) {
            throw new Error("Mật khẩu đã nhập không trùng khớp !");
        }
    };

    return (
        <NewForm form={form} onFinish={handleSubmitAccount} className="new-form">
            <div className="profile-container">
                <h3 style={{ marginTop: 30 }}>Thiết lập tài khoản</h3>
                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <FieldFloatItem name="username" label="Username">
                            <Input disabled={true} />
                        </FieldFloatItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <FieldFloatItem
                            name="old_password"
                            label="Mật khẩu cũ"
                            rules={[
                                {
                                    required: true,
                                    message: "Mật khẩu cũ không được để trống !",
                                },
                            ]}
                        >
                            <Input type="password" />
                        </FieldFloatItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <FieldFloatItem
                            name="new_password"
                            label="Mật khẩu mới"
                            rules={[
                                {
                                    required: true,
                                    message: "Mật khẩu mới không được để trống !",
                                },
                                {
                                    validator: checkPassword,
                                },
                            ]}
                        >
                            <Input type="password" />
                        </FieldFloatItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <FieldFloatItem
                            name="confirm_password"
                            label="Xác nhận mật khẩu"
                            rules={[
                                {
                                    required: true,
                                    message: "Hãy nhập lại mật khẩu lần nữa !",
                                },
                                {
                                    validator: checkConfirm,
                                },
                            ]}
                        >
                            <Input type="password" onBlur={handleConfirmBlur} />
                        </FieldFloatItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                window.history.back();
                            }}
                        >
                            Quay lại
                        </Button>
                        <Button type="primary" htmlType="submit" className="submit-button">
                            Cập nhật
                        </Button>
                    </Col>
                </Row>
            </div>
        </NewForm>
    );
}

export default ProfileComponent;
