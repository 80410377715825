import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import { numberWithCommas } from "@utils";

import { api_getRevenue } from "./apiDashboard";

const Money = (props) => {
    const { time } = props;
    const branchRedux = useSelector((state) => state.branch.branch_id);
    const [optionsProfit, setOptionsProfit] = useState({
        chart: {
            height: "100%",
            animations: {
                enabled: false,
            },
            id: "basic-bar",
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    console.log(config);
                },
            },
        },
        xaxis: {
            categories: [],
            labels: {
                rotate: -45,
            },
            title: {
                text: "Thời gian",
                style: {
                    colors: [],
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    cssClass: "apexcharts-yaxis-label",
                },
            },
        },
        yaxis: {
            title: {
                text: "Tiền",
                style: {
                    colors: [],
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                    cssClass: "apexcharts-yaxis-label",
                },
            },
            labels: {
                formatter: function (value) {
                    return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(value);
                },
            },
        },
        noData: {
            text: "Không có dữ liệu",
        },
        title: {
            text: "Thống kê doanh thu & chi",
            align: "center",
            style: {
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Arial, sans-serif",
            },
        },
    });
    const [seriesProfit, setSeriesProfit] = useState([]);
    useEffect(() => {
        getRevenue();
    }, [branchRedux, time]);

    const countSum = (arr) => arr.reduce((result, currentValue) => (result += currentValue));

    const getRevenue = async () => {
        const res = await api_getRevenue({ branch_id: branchRedux, time });

        if (res.status) {
            setOptionsProfit({
                ...optionsProfit,

                xaxis: {
                    ...optionsProfit.xaxis,
                    categories: res.months.map((item, index) => {
                        return `Tháng ${item}`;
                    }),
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                colors: ["#2E93fA", "#d82025"],
                legend: {
                    show: true,
                    horizontalAlign: "center",
                    itemMargin: {
                        vertical: 5,
                    },
                },
                title: {
                    ...optionsProfit.title,
                    text: `Thống kê doanh thu & lợi nhuận (${numberWithCommas(countSum(res.values_thu))} & ${
                        countSum(res.values_thu) - countSum(res.values_chi) > 0
                            ? "+ " + numberWithCommas(countSum(res.values_thu) - countSum(res.values_chi))
                            : "- " + numberWithCommas(countSum(res.values_chi) - countSum(res.values_thu))
                    })`,
                },
            });
            setSeriesProfit([
                { name: "Doanh thu", data: res.values_thu },
                {
                    name: "Chi tiêu",
                    data: res.values_chi,
                },
            ]);
        }
    };
    return <div>{<Chart options={optionsProfit} series={seriesProfit} type="line" height="400px" width="100%" />}</div>;
};

export default Money;
