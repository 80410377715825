//React functions
import React, { useState, useEffect } from "react";

//Ant Component
import {
    Modal,
    Button,
    Form,
    Input,
    Row,
    Col,
    notification,
    Tabs,
    Select,
    DatePicker,
    Checkbox,
    Breadcrumb,
} from "antd";

//Self-defined Component
import { InputCurrency } from "@components/Input";
import NewForm, { FieldFloatItem } from "@components/NewForm";
import CenterArea from "../../components/CenterArea";

//Router
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

//Api
import {
    createStudentCourse,
    getCourseOfType,
    getStudentCourse,
    updateStudentCourse,
    api_getEndDateCourse,
    api_getCards,
} from "./apiStudent";
import {
    api_getAllBranchOptions,
    api_getAllTypeOfClass,
    api_getAllPromotions,
    api_getCheckValidPromotion,
    api_getCardsByBranchAndClassType,
} from "@api/globalApi";

//third-party libraries
import moment from "moment";
import debounce from "lodash/debounce";

//function from uitls
import { getUserInfo } from "@utils/auth";
import { validateObject, numberWithCommas, numberWithoutCommas, toArray } from "@utils";
import { numberValidation } from "@utils/validation";

let { TextArea } = Input;
let { TabPane } = Tabs;
let { Option } = Select;
let FormItem = Form.Item;

let listStatus = [
    { id: "1", name: "Đang học" },
    // { id: "2", name: "Kết thúc" },
    { id: "3", name: "Hủy khóa" },
    { id: "4", name: "Bảo lưu" },
];

// let listCombo = [
//     { id: 1, name: "1 khóa" },
//     { id: 2, name: "2 khóa" },
//     { id: 3, name: "3 khóa" },
//     { id: 4, name: "4 khóa" },
//     { id: 5, name: "5 khóa" },
// ];

const CourseDetail = () => {
    //define instance
    let history = useHistory();
    let { state, pathname } = useLocation();
    let { student_id, course_id, student_course_id } = useParams();
    const [form] = NewForm.useForm();
    let user = getUserInfo();

    //state
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [listCourse, setListCourse] = useState([]);
    const [listBranch, setListBranch] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [statusId, setStatusId] = useState(null);
    const [visibleDateReserve, setVisibleDateReserve] = useState(false);
    const [listTypeOfClass, setListTypeOfClass] = useState([]);
    const [listCard, setListCard] = useState([]);
    const [listPromotions, setListPromotions] = useState([]);

    const handleBack = () => {
        history.goBack();
    };

    const validateData = (data) => {
        let dataSubmit = validateObject(data);
        dataSubmit.student_id = +student_id;
        dataSubmit.tuition = +numberWithoutCommas(dataSubmit.card_value);
        dataSubmit.type_id = +dataSubmit.type_id;
        dataSubmit.begin_date = dataSubmit.begin_date ? dataSubmit.begin_date.format("YYYY-MM-DD") : null;
        dataSubmit.end_date = dataSubmit.end_date ? dataSubmit.end_date.format("YYYY-MM-DD") : null;
        dataSubmit.card_id = +dataSubmit.card_id;
        dataSubmit.card_value = +numberWithoutCommas(dataSubmit.card_value);
        dataSubmit.create_bill = dataSubmit.create_bill ? true : false;
        dataSubmit.branch_id = +dataSubmit.branch_id;
        dataSubmit.course_id = +dataSubmit.course_id;
        dataSubmit.note = dataSubmit.note || "";
        dataSubmit.status = dataSubmit.status || "1";
        dataSubmit.promotion_id = +dataSubmit.promotion_id;
        dataSubmit.discount = +numberWithoutCommas(dataSubmit.discount);
        delete dataSubmit.total_tuition;
        return dataSubmit;
    };

    const onUpdate = () => {
        setLoading(true);
        let textAlert = "";
        form.validateFields()
            .then(async (values) => {
                let dataSubmit = validateData(values);

                if (localStorage.getItem("form_student_course_data")) {
                    let dataFromLocalStorage = JSON.parse(localStorage.getItem("form_data"));
                    if (+dataFromLocalStorage.branch_id !== dataSubmit.branch_id) {
                        textAlert = "Chi nhánh";
                    }
                    if (+dataFromLocalStorage.type_id !== dataSubmit.type_id) {
                        textAlert = "Loại lớp";
                    }
                    if (+dataFromLocalStorage.course_id !== dataSubmit.course_id) {
                        textAlert = "Khóa học";
                    }
                }

                Modal.confirm({
                    title: "Bạn có chắc chắn muốn cập nhật?",
                    content: textAlert ? (
                        <span>
                            <strong>{textAlert}</strong>
                            &nbsp;đã bị thay đổi, điều này sẽ làm sai thông tin về khóa học của học viên !!!
                        </span>
                    ) : null,
                    async onOk() {
                        if (course_id) {
                            if (statusId == 4) {
                                dataSubmit.date_continue = dataSubmit.date_continue
                                    ? dataSubmit.date_continue.format("YYYY-MM-DD")
                                    : moment(new Date(), "YYYY-MM-DD");
                            }
                            dataSubmit.student_course_id = +student_course_id;
                            delete dataSubmit.create_bill;
                            let res = await updateStudentCourse(dataSubmit);
                            if (res.status) {
                                notification.success({
                                    message: "Thông báo",
                                    description: res.message || "Cập nhật thành công",
                                });
                                localStorage.removeItem("form_data");
                            } else {
                                notification.error({
                                    message: "Cảnh báo",
                                    description: res.message || "Lỗi hệ thống",
                                });
                            }
                            setLoading(false);
                        }
                    },
                    onCancel() {
                        setLoading(false);
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({
                    message: "Cảnh báo",
                    description: "Lỗi hệ thống",
                });
                setLoading(false);
            });
    };

    const onCreate = async (values) => {
        let dataSubmit = validateData(values);
        delete dataSubmit.student_course_id;
        let res = await createStudentCourse(dataSubmit);
        if (res.status) {
            notification.success({
                message: "Thông báo",
                description: res.message || "Tạo mới thành công",
            });
            history.goBack();
        } else {
            notification.error({
                message: "Cảnh báo",
                description: res.message || "Lỗi hệ thống",
            });
        }
        setLoading(false);
    };

    const initFetch = async () => {
        const res = await getStudentCourse(student_course_id, {
            student_id,
            course_id,
        });
        if (res.status) {
            let resCards = await Promise.all([
                _getAllTypeOfClass(res.data?.branch_id),
                _getCourseOfType(res.data?.branch_id, res.data?.type_id),
                _getCardsByBranchAndClassType(res.data?.branch_id, res.data?.type_id),
            ]);
            setListCard(resCards[2]);
            let newCardValue = resCards[2].find((item) => item.id == res.data.card_id)?.value ?? 0;
            let newDiscountValue = newCardValue - (res.data.tuition ?? 0);
            newDiscountValue > 0 ? newDiscountValue : 0;
            setBranchId(res.data?.branch_id?.toString());
            // setStatusId(res.data?.status?.toString());
            form.setFieldsValue({
                branch_id: res.data?.branch_id?.toString(),
                type_id: res.data?.type_id?.toString(),
                course_id: res.data?.course_id?.toString(),
                // combo: res.data?.combo?.toString(),
                status: res.data?.status?.toString(),
                note: res.data.note,
                total_tuition: res.data.tuition ? numberWithCommas(res.data.tuition) : "0",
                card_id: res.data.card_id?.toString(),
                card_value: newCardValue ? numberWithCommas(newCardValue) : "0",
                begin_date: res.data.begin_date ? moment(res.data.begin_date, "YYYY-MM-DD") : null,
                end_date: res.data.end_date ? moment(res.data.end_date, "YYYY-MM-DD") : null,
                date_continue: moment(new Date(), "YYYY-MM-DD").add(1, "days"),
                promotion_id: res.data.promotion_id?.toString(),
                discount: newDiscountValue ? numberWithCommas(newDiscountValue) : "0",
            });

            localStorage.setItem(
                "form_student_course_data",
                JSON.stringify({
                    branch_id: res.data?.branch_id?.toString(),
                    type_id: res.data?.type_id?.toString(),
                    course_id: res.data?.course_id?.toString(),
                })
            );
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    useEffect(() => {
        setVisible(false);
        async function fetchAll() {
            notification.config({
                placement: "bottomRight",
                bottom: 20,
                duration: 5,
            });
            let res = await Promise.all([_getAllBranch(), _getAllPromotions()]);
            setListPromotions(res[1]);
            setBranchId(user.branch_id);
            if (course_id) {
                initFetch();
                setVisible(true);
            } else {
                form.setFieldsValue({
                    branch_id: user?.branch_id?.toString(),
                    create_bill: true,
                    // combo: 1,
                });
                setVisible(true);
            }
        }
        fetchAll();
    }, []);

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    const _getAllBranch = async () => {
        const res = await api_getAllBranchOptions();
        if (res.status) {
            if (res.data) {
                setListBranch(toArray(res.data));
            }
        } else {
            console.log("err: ", res);
        }
    };

    const _getCardsByBranchAndClassType = async (branch_id, type_of_class_id) => {
        try {
            const params = { branch_id };
            const res = await api_getCardsByBranchAndClassType(type_of_class_id, { params });
            if (res.status) {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };

    const _getAllPromotions = async () => {
        try {
            const res = await api_getAllPromotions({ paging: 0 });
            if (res.status) {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };

    const _getAllTypeOfClass = async (branch_id) => {
        const params = {
            paging: 0,
            filter: `branch_id:${branch_id};`,
        };
        try {
            const res = await api_getAllTypeOfClass(params);
            if (res.status) {
                setListTypeOfClass(res.data);
            } else {
                setListTypeOfClass([]);
            }
        } catch (err) {
            setListTypeOfClass([]);
        }
    };

    const _getCourseOfType = async (branch_id, type_id) => {
        try {
            const res = await getCourseOfType({
                _paging: 0,
                _filter: `branch_id:${branch_id};type_id:${type_id}`,
            });
            if (res.status) {
                setListCourse(res.data);
            } else {
                setListCourse([]);
            }
        } catch (err) {
            setListCourse([]);
        }
    };

    const handleChangeType = async (value) => {
        try {
            let res = await Promise.all([
                _getCardsByBranchAndClassType(branchId, value),
                _getCourseOfType(branchId, value),
            ]);
            setListCard(res[0]);
        } catch {
            setListCourse([]);
        } finally {
            // form.setFieldsValue({ course_id: "" });
            form.setFieldsValue({
                course_id: "",
                tuition: null,
                tuition_per_day: null,
                total_tuition: null,
                card_id: "",
                promotion_id: "",
                card_value: "",
                discount: "",
            });
        }
    };

    const handleChangeBranch = async (value) => {
        setBranchId(value);
        await _getAllTypeOfClass(value);
        form.setFieldsValue({
            branch_id: value,
            course_id: "",
            tuition: null,
            type_id: "",
            total_tuition: null,
            card_id: "",
            promotion_id: "",
            card_value: "",
            discount: "",
            begin_date: null,
            end_date: null,
        });
    };

    const handleChangeCourse = async (value) => {
        let currentCourse = listCourse && listCourse.find((item) => item.id == value);
        form.setFieldsValue({
            tuition: currentCourse.continuity
                ? "0"
                : currentCourse.tuition
                ? numberWithCommas(currentCourse.tuition)
                : "0",
            tuition_per_day: currentCourse.continuity
                ? currentCourse.tuition
                    ? numberWithCommas(currentCourse.tuition)
                    : "0"
                : "0",
            // begin_date: currentCourse.begin_date ? moment(currentCourse.begin_date, "YYYY-MM-DD") : null,
            // end_date: currentCourse.end_date ? moment(currentCourse.end_date, "YYYY-MM-DD") : null,
            continuity: currentCourse.continuity,
            total_tuition: null,
            card_id: "",
            promotion_id: "",
            card_value: "",
            discount: "",
            begin_date: null,
            end_date: null,
        });
    };

    const onChangeStatus = (value) => {
        if (statusId == 4 && value == 1) {
            setVisibleDateReserve(true);
        } else {
            setVisibleDateReserve(false);
        }
    };

    const _getEndDateCourse = async (begin_date, course_id, card_id) => {
        const params = {
            params: {
                begin_date,
                course_id,
                card_id,
            },
        };
        let res = await api_getEndDateCourse(params);
        if (res.status) {
            const end_date = moment(res.end_date, "YYYY-MM-DD");
            form.setFieldsValue({ end_date: end_date });
            if (res.warning) {
                notification.warning({
                    message: "Thông báo",
                    description: res.warning,
                });
            }
        } else {
            form.setFieldsValue({ end_date: null });
            notification.error({
                message: "Lỗi",
                description: res.message || "Lỗi hệ thống",
            });
        }
    };

    const handleChangeBeginDate = async (value) => {
        const begin_date = value?.format("YYYY-MM-DD");
        const course_id = form.getFieldValue("course_id");
        const card_id = form.getFieldValue("card_id");
        _getEndDateCourse(begin_date, course_id, card_id);
    };

    const handleChangeCardId = (card_id) => {
        let newCardValue = listCard.find((item) => item.id === +card_id)?.value || 0;
        let promotion_id = form.getFieldValue("promotion_id");
        let discount = listPromotions.find((item) => item.id === promotion_id)?.value || 0;
        let newDiscountValue = discount <= 100 ? (newCardValue * discount) / 100 : discount;
        let newTotalTuition = newCardValue - newDiscountValue > 0 ? newCardValue - newDiscountValue : 0;
        newTotalTuition = newCardValue - newDiscountValue;
        newTotalTuition > 0 ? newTotalTuition : 0;
        form.setFieldsValue({
            total_tuition: numberWithCommas(newTotalTuition),
            card_id: card_id,
            card_value: numberWithCommas(newCardValue),
        });
    };

    const handleChangePromotion = async (promotion_id) => {
        let res = await api_getCheckValidPromotion(promotion_id);
        if (res.status) {
            res.success &&
                notification.success({
                    message: "Thông báo",
                    description: res.success,
                });
            let discount = listPromotions.find((item) => item.id === +promotion_id)?.value || 0;
            let newCardValue = +numberWithoutCommas(form.getFieldValue("card_value")) || 0;
            let newDiscountValue = discount <= 100 ? (newCardValue * discount) / 100 : discount;
            let newTotalTuition = newCardValue - newDiscountValue > 0 ? newCardValue - newDiscountValue : 0;
            form.setFieldsValue({
                total_tuition: numberWithCommas(newTotalTuition),
                discount: numberWithCommas(newDiscountValue),
            });
        } else {
            let discount = 0;
            let newCardValue = +numberWithoutCommas(form.getFieldValue("card_value")) || 0;
            let newDiscountValue = discount <= 100 ? (newCardValue * discount) / 100 : discount;
            let newTotalTuition = newCardValue - newDiscountValue > 0 ? newCardValue - newDiscountValue : 0;
            form.setFieldsValue({
                total_tuition: numberWithCommas(newTotalTuition),
                discount: numberWithCommas(newDiscountValue),
            });
            notification.warning({
                message: "Thông báo",
                description: res.warning || "Chương trình đã hết hiệu lực. Vui lòng chọn chương trình khác",
            });
        }
    };

    return (
        <>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item className="name-breadcrumb">Học viên</Breadcrumb.Item>
                <Breadcrumb.Item className="name-breadcrumb">
                    <Link to={"/student-course/" + student_id} className="is-link">
                        Khóa học
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="name-breadcrumb">{course_id ? "Cập nhật" : "Tạo mới"}</Breadcrumb.Item>
            </Breadcrumb>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Thông tin khóa học" key="1">
                    <CenterArea xxl={1350} xl={940} xs="100%">
                        <NewForm
                            className="new-form"
                            form={form}
                            visible={visible}
                            onFinish={course_id ? null : onCreate}
                        >
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={24}>
                                    <FieldFloatItem
                                        label="Chi nhánh"
                                        name="branch_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn chi nhánh",
                                            },
                                        ]}
                                    >
                                        <Select disabled={!user.is_admin} onChange={handleChangeBranch}>
                                            {listBranch &&
                                                listBranch.map((item, i) => {
                                                    return (
                                                        <Option key={i} value={item.id.toString()}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem
                                        label="Loại lớp"
                                        name="type_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn loại lớp",
                                            },
                                        ]}
                                    >
                                        <Select onChange={handleChangeType}>
                                            {listTypeOfClass.map((item) => (
                                                <Option key={item.id} value={item.id.toString()}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>

                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem
                                        label="Khóa học"
                                        name="course_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn khóa học",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={handleChangeCourse}
                                        >
                                            {listCourse.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id.toString()}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                            {/* <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={24}>
                                <FieldFloatItem label="Thời gian muốn học" name="combo">
                                    <Select
                                        disabled={!user.is_admin || !pathname.includes("/add-student-course")}
                                        onChange={handleChangeCombo}
                                    >
                                        {listCombo &&
                                            listCombo.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </FieldFloatItem>
                            </Col>
                        </Row> */}

                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem
                                        label="Loại thẻ"
                                        name="card_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn loại thẻ",
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={handleChangeCardId}
                                        >
                                            {listCard.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id.toString()}>
                                                        {item.type_card}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem
                                        label="Ngày bắt đầu"
                                        name="begin_date"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn ngày bắt đầu",
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder={null}
                                            format="DD/MM/YYYY"
                                            onChange={handleChangeBeginDate}
                                        />
                                    </FieldFloatItem>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Ngày kết thúc" name="end_date">
                                        <DatePicker
                                            disabled
                                            style={{ width: "100%" }}
                                            placeholder={null}
                                            format="DD/MM/YYYY"
                                        />
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Giá trị thẻ" name="card_value">
                                        <InputCurrency disabled />
                                    </FieldFloatItem>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={8}>
                                    <FieldFloatItem label="Chương trình khuyến mại" name="promotion_id">
                                        <Select
                                            style={{ width: "100%" }}
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={handleChangePromotion}
                                        >
                                            {listPromotions.map((item, i) => {
                                                return (
                                                    <Option key={i} value={item.id.toString()}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <FieldFloatItem label="Giảm giá" name="discount">
                                        <InputCurrency disabled />
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <FieldFloatItem label="Học phí thực đóng" name="total_tuition">
                                        <InputCurrency disabled />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                            {/* {pathname.includes("/add-student-course") && (
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Số buổi học trong tháng" name="count_lesson_expected">
                                        <Input disabled={true} />
                                    </FieldFloatItem>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <FieldFloatItem label="Tiền phải đóng" name="amount_expected">
                                        <InputCurrency disabled={true} style={{ color: "rgba(0, 0, 0, 0.9)" }} />
                                    </FieldFloatItem>
                                </Col>
                            </Row>
                        )} */}
                            {/* {!pathname.includes("/add-student-course") && (
                                <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Trạng thái" name="status">
                                            <Select
                                                onChange={onChangeStatus}
                                                disabled={form.getFieldValue("status") == "3"}
                                            >
                                                {listStatus &&
                                                    listStatus.map((item, i) => {
                                                        return (
                                                            <Option key={i} value={item.id.toString()}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </FieldFloatItem>
                                    </Col>
                                </Row>
                            )} */}
                            {!pathname.includes("/add-student-course") && visibleDateReserve && (
                                <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                    <Col xs={24} sm={24} md={24}>
                                        <FieldFloatItem label="Ngày học lại" name="date_continue">
                                            <DatePicker
                                                disabledDate={disabledDate}
                                                style={{ width: "100%" }}
                                                placeholder={null}
                                                format="DD/MM/YYYY"
                                            />
                                        </FieldFloatItem>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={{ xs: 8, sm: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={24}>
                                    <FieldFloatItem label="Ghi chú" name="note">
                                        <TextArea name="note" rows={2} />
                                    </FieldFloatItem>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={20}>
                                    <Form.Item>
                                        <Row type="flex">
                                            <Col style={{ marginRight: 10 }}>
                                                <Button type="default" onClick={handleBack}>
                                                    Quay lại
                                                </Button>
                                            </Col>
                                            <Col style={{ marginRight: 20 }}>
                                                <Button
                                                    htmlType={course_id ? "button" : "submit"}
                                                    onClick={course_id ? onUpdate : null}
                                                    type="primary"
                                                    loading={loading}
                                                >
                                                    {course_id ? "Lưu" : "Tạo mới"}
                                                </Button>
                                            </Col>
                                            {pathname.includes("/add-student-course") && (
                                                <Col>
                                                    <FormItem label="" name="create_bill" valuePropName="checked">
                                                        <Checkbox>Xuất phiếu thu</Checkbox>
                                                    </FormItem>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </NewForm>
                    </CenterArea>
                </TabPane>
            </Tabs>
        </>
    );
};

export default CourseDetail;
