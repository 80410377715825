import React from "react";
import moment from "moment";
import { Image, Popover, Tag } from "antd";
import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";

import ConfigColumnsForTable from "@components/ConfigColumnsForTable";

export const createColumnConfig = (configColumns, props) => {
    const { pagination, fixColumns, setConfigColumns, history, tableName, listTypeOfClass } = props;

    let localColumns = JSON.parse(localStorage.getItem(tableName));
    const resetAll = () => {
        let localColumns = JSON.parse(localStorage.getItem(tableName));
        if (localColumns) {
            localStorage.removeItem(tableName);
            history.push("/teacher");
        }
    };
    let defaultColumn = [
        {
            title: (
                <div className="more-action">
                    <Popover
                        overlayClassName="popover-config-columns"
                        placement="bottom"
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}
                            >
                                <div>Reset All</div>
                                <ReloadOutlined onClick={resetAll} />
                            </div>
                        }
                        content={
                            <div className="wrap-checkbox">
                                <ConfigColumnsForTable
                                    tableName={tableName}
                                    fixColumns={fixColumns}
                                    configColumns={configColumns}
                                    setConfigColumns={setConfigColumns}
                                />
                            </div>
                        }
                        trigger="click"
                    >
                        <MoreOutlined />
                    </Popover>
                </div>
            ),
            key: "stt",
            width: 70,
            fixed: "left",
            align: "center",
            render: (text, record, index) => (
                <React.Fragment>{(pagination.current - 1) * pagination.per_page + index + 1}</React.Fragment>
            ),
        },
        {
            title: <span className="dragHandler">Ảnh giáo viên</span>,
            key: "image",
            dataIndex: "image",
            width: 130,
            align: "center",
            render: (text, record, index) => {
                return text ? <Image src={text} style={{ width: 75, height: "auto" }} /> : <div>Chưa có</div>;
            },
        },
        {
            title: <span className="dragHandler">Họ tên</span>,
            key: "name",
            dataIndex: "name",
            width: 180,
            align: "center",
        },
        {
            title: <span className="dragHandler">Các loại môn dạy</span>,
            dataIndex: "class_type_names",
            width: 300,
            align: "center",
            key: "class_type_names",
            render: (text, record, index) => {
                return record.class_type_names.map((item, index) => (
                    <Tag style={{ marginBottom: 5, fontSize: 14, fontWeight: 500 }} key={index} color="#108ee9">
                        {item}
                    </Tag>
                ));
            },
        },
        // {
        //     title: <span className="dragHandler">Các loại môn dạy</span>,
        //     dataIndex: "class_type_ids",
        //     width: 300,
        //     align: "center",
        //     key: "class_type_ids",
        //     filters: listTypeOfClass.map((item) => {
        //         return { value: item.id, text: item.name };
        //     }),
        //     defaultFilteredValue: ["1"],
        //     onFilter: (value, record) => record.class_type_ids.indexOf(value) === 0,
        //     render: (text, record, index) => {
        //         return record.class_type_ids.map((item, index) => {
        //             return (
        //                 listTypeOfClass.length &&
        //                 listTypeOfClass.map((i) => {
        //                     return (
        //                         item == i.id && (
        //                             <Tag
        //                                 style={{ marginBottom: 5, fontSize: 14, fontWeight: 500 }}
        //                                 key={index}
        //                                 color="#108ee9"
        //                             >
        //                                 {i.name}
        //                             </Tag>
        //                         )
        //                     );
        //                 })
        //             );
        //         });
        //     },
        // },
        {
            title: <span className="dragHandler">Giới tính</span>,
            key: "gender",
            dataIndex: "gender",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return <div>{text == 0 ? "Nữ" : "Nam"}</div>;
            },
        },
        {
            title: <span className="dragHandler">Email</span>,
            key: "email",
            dataIndex: "email",
            width: 200,
            align: "center",
        },
        {
            title: <span className="dragHandler">Số điện thoại</span>,
            key: "phone",
            dataIndex: "phone",
            width: 150,
            align: "center",
        },
        {
            title: <span className="dragHandler">Địa chỉ</span>,
            key: "address",
            dataIndex: "address",
            width: 250,
            align: "center",
        },
        {
            title: <span className="dragHandler">Trạng thái</span>,
            key: "status",
            dataIndex: "status",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return <div>{text == "1" ? "Đang dạy" : "Đã nghỉ"}</div>;
            },
        },
        {
            title: <span className="dragHandler">Ghi chú</span>,
            key: "note",
            dataIndex: "note",
            width: 250,
            align: "center",
        },
        {
            title: "Ngày tạo",
            key: "created_at",
            dataIndex: "created_at",
            width: 120,
            align: "center",
            render: (text, record) => {
                return (
                    <React.Fragment>
                        {record.created_at ? moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""}
                    </React.Fragment>
                );
            },
        },
    ];

    let result = [];
    if (localColumns) {
        defaultColumn.filter((item) =>
            localColumns.map((i) => {
                if (i.status == true && item.key == i.key) {
                    result.push(item);
                }
            })
        );
        return result;
    } else {
        return defaultColumn.filter((item) => configColumns.includes(item.key));
    }
};
