import api from "@api";

export const api_getListStudentMoney = (param) => {
    const url = `statistic/remind_students_to_pay`;
    return api.get(url, {
        params: {
            branch_id: param?.branch_id,
        },
    });
};

export const api_getAllInvoices = (param) => {
    const url = "/invoice";
    return api.get(url, {
        params: {
            _paging: param?.paging,
        },
    });
};

export const api_getAllCourses = (param) => {
    const url = `/course`;
    return api.get(url, {
        params: {
            _page: param.page,
            _filter: param.filters,
            _paging: param?.paging,
        },
    });
};

export const api_getAllStudent = async (param) => {
    const url = "/student";
    return api.get(url, {
        params: {
            _page: param?.page,
            _filter: param?.filters,
            _paging: param?.paging,
        },
    });
};

export const api_getAllTeacher = (params) => {
    const url = `/teacher`;
    return api.get(url, {
        params: {
            _page: params?.page,
            _filter: params?.filters,
            _paging: params?.paging,
        },
    });
};

export const api_getAllBranch = (params) => {
    const url = `/branch`;
    return api
        .get(url, {
            params: { ...params, _orderBy: "id:asc" },
        })
        .catch((err) => err);
};

export const api_getAllBranchOptions = (params) => {
    const url = `/branch/options`;
    return api
        .get(url, {
            params: { ...params, _orderBy: "id:asc", _filter: "active:1" },
        })
        .catch((err) => err);
};

export const api_getAllCourse = (params) => {
    const url = `/course`;
    return api.get(url, { params }).catch((err) => err);
};

export const api_uploadImage = (file) => {
    const url = `/upload`;
    var bodyFormData = new FormData();
    if (file) {
        bodyFormData.append("file", file.originFileObj);
        api.defaults.headers.common["Content-Type"] = "multipart/form-data";
        return api.post(url, bodyFormData);
    } else {
        return null;
    }
};

export const api_deleteImage = (file) => {
    const url = `/delete_file`;
    return api.post(url, { filename: file });
};

export const api_getAllTypeOfClass = (param) => {
    const url = "/class_type";
    return api.get(url, {
        params: {
            _paging: param?.paging,
            _filter: param?.filter || "",
            _relations: "courses",
        },
    });
};

export const api_getAllCards = (params) => {
    const url = `/card`;
    return api.get(url, params);
};

export const api_getCardsByBranchAndClassType = (type_of_class_id, params) => {
    const url = `/class_type_for_card/${type_of_class_id}`;
    return api.get(url, params);
};

export const api_getAllProvince = () => {
    const url = "/get_province";
    return api.get(url);
};

export const api_getDistrictByProvince = (provinceId) => {
    const url = `/get_district/${provinceId}`;
    return api.get(url);
};

export const api_getAllPromotions = (params) => {
    const url = `/promotion`;
    return api.get(url, params);
};

export const api_getCheckValidPromotion = (promotion_id) => {
    const url = `/check_promotion/${promotion_id}`;
    return api.get(url);
};
