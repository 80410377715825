// export const nameValidation = new RegExp(
//     /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/g
// );

// export const phoneValidation = new RegExp(/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g);
export const phoneValidation = new RegExp(/(03|05|07|08|09)+([0-9]{8})\b/g);
export const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
);

export const numberValidation = new RegExp(/^\d+$/g);
export const currencyValidation = new RegExp(/^(?!0,00)[1-9]\d{0,2}(\.\d{3})*(,\d\d)?$/g);
export const percentageValidation = new RegExp(/(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/g);
